import React, { useState, useEffect, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Form } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from '@mui/material';


import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArifactInfo from "./ArtifactInfo";
import Assignuser12 from "../../AssignuserModels/ArtifactAssignusermodel";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import S3 from "react-aws-s3";
import Modal from "@mui/material/Modal";
import AttachFileIcon from "@mui/icons-material/FilePresent";
import Badge from "@mui/material/Badge";
import TaskAltIcon from "@mui/icons-material/InsertDriveFile";
import useWindowDimensions from "../../../useWindowDimensions";
import { deepOrange, deepPurple } from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import "../../../ButtonStyles/AppStyles.css";

import cookie from "react-cookies";
import Skeleton from "@mui/material/Skeleton";
import { decryption } from "../../../auth/utilities";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Backdrop,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Drawer,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Switch,
  TextField,
  Snackbar,
  Alert
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import Addsubartifact_popup from "./Addsubartifact_popup";
import Loadingbutton from "@mui/lab/LoadingButton";
import LinearProgress from "@mui/material/LinearProgress";

window.Buffer = window.Buffer || require("buffer").Buffer;
const style1 = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const style12 = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const style = {
  position: "absolute",
  top: "70%",
  left: "30%",
  transform: "translate(-50%, -50%)",
  minWidth: 200,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    maxWidth: "100%",
    textOoverflow: "ellipsis",

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function Myartifacts(event) {
  const subdomain = window.location.host.split(".")[0];
  const [checkList, setCheckList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [openid, setopenid] = useState();
  const [Loading, setLoading] = React.useState(false);
  const [gaps, setgaps] = useState([]);
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Open1, setOpen1] = useState(false);
  const [SubArtifact, setSubArtifact] = useState([]);
  const [modalopen, setmodalOpen] = React.useState(false);
  const handlemodalOpen = () => setmodalOpen(true);
  const handlemodalClose = () => setmodalOpen(false);
  const [tenant_artifact_id, settenant_artifact_id] = useState({});
  const [openform, setOpenform] = React.useState(false);
  const handleOpenform = () => setOpenform(true);
  const [EveidanceDate, setEveidanceDate] = React.useState("");
  const [Eveidance_exp_Date, setEveidance_exp_Date] = React.useState("");
  const [S3filelink, setS3filelink] = React.useState(null);
  const [Mapping, setMapping] = React.useState(false);
  const [Artifact, setartifact] = useState([1]);
  const [openform0, setOpenform0] = React.useState(false);
  const [Uploading, setUploading] = React.useState(false);
  const handleOpenform0 = () => setOpenform0(true);
  const [openform01, setOpenform01] = React.useState(false);
  const handleOpenform01 = () => setOpenform01(true);
  const handleCloseform01 = () => setOpenform01(false);
  const [EveidanceName0, setEveidanceName0] = React.useState("");
  const [EveidanceDate0, setEveidanceDate0] = React.useState("");
  const [Eveidance_exp_Date0, setEveidance_exp_Date0] = React.useState("");
  const [userList, setUserList] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState([]);
  const [selectedControl, setSelectedControl] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");  
  const [orderBy, setOrderBy] = useState("artifact_name"); 
  const [page, setPage] = useState(0);   
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  const handleCloseform0 = () => {
    setOpenform0(false); 
    setS3filelink(null);  
    setUploading(false); 
    setS3filelink0(null); 
  };

  const handleCloseform = () => {
    setOpenform(false);   
    setS3filelink(null);   
    setUploading(false);   
    setS3filelink0(null); 
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Assuming that question is the data you want to filter.
  const safeData = question || [];


  const [S3filelink0, setS3filelink0] = React.useState(null);
  const [Mappind_masterdata, setMappind_masterdata] = useState({
    compliance: [
      {
        id: 1,
        label: "Loading...",
      },
    ],
    process: [
      {
        id: 1,
        label: "Loading...",
      },
    ],
    control: [
      {
        id: 1,
        label: "Loading...",
      },
    ],
  });

  const complianceOptions = useMemo(() => {
    const compliances = new Set();
    safeData.forEach((item) => {
      if (item.cpca && Array.isArray(item.cpca)) {
        item.cpca.forEach((c) => {
          if (c.compliance_name) {
            compliances.add(c.compliance_name);
          }
        });
      }
    });
    return Array.from(compliances);
  }, [safeData]);

  const processOptions = useMemo(() => {
  const filteredDataBySearch = safeData.filter((item) => {
    return (
      searchTerm === "" ||
      item.cpca?.some((c) =>
        c.process_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });
  
  const processes = new Set();
  filteredDataBySearch.forEach((item) => {
    if (item.cpca && Array.isArray(item.cpca)) {
      item.cpca.forEach((c) => {
        if (c.process_name) {
          processes.add(c.process_name);
        }
      });
    }
  });
  
  return Array.from(processes);
}, [safeData, searchTerm]);

const controlOptions = useMemo(() => {
  const filteredDataBySearch = safeData.filter((item) => {
    return (
      searchTerm === "" ||
      item.cpca?.some((c) =>
        c.control_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });

  const controls = new Set();
  filteredDataBySearch.forEach((item) => {
    if (item.cpca && Array.isArray(item.cpca)) {
      item.cpca.forEach((c) => {
        if (c.control_name) {
          controls.add(c.control_name);
        }
      });
    }
  });
  
  return Array.from(controls);
}, [safeData, searchTerm]);

const standardOptions = useMemo(() => {
  const filteredDataBySearch = safeData.filter((item) => {
    return (
      searchTerm === "" ||
      item.standards?.some((standard) =>
        typeof standard === 'string'
          ? standard.toLowerCase().includes(searchTerm.toLowerCase())
          : standard?.standard_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });

  return [
    ...new Set(
      filteredDataBySearch
        .flatMap((item) =>
          Array.isArray(item.standards)
            ? item.standards.map((standard) => {
                return typeof standard === 'string'
                  ? standard
                  : standard?.standard_name;
              })
            : []
        )
        .filter((name) => name) // Ensure it's not null or undefined
    ),
  ];
}, [safeData, searchTerm]);

// Filtered data to render the artifacts
const processedData = useMemo(() => {
  const filtered = safeData.filter((item) => {
    const complianceMatch =
      selectedCompliance.length === 0 ||
      item.cpca?.some((c) => selectedCompliance.includes(c.compliance_name));

    const processMatch =
      selectedProcess.length === 0 ||
      item.cpca?.some((c) => selectedProcess.includes(c.process_name));

    const controlMatch =
      selectedControl.length === 0 ||
      item.cpca?.some((c) => selectedControl.includes(c.control_name));

    const standardMatch =
      selectedStandard.length === 0 ||
      item.standards?.some((s) => selectedStandard.includes(s.standard_name));

    const searchMatch =
      searchTerm === "" ||
      item.cpca?.some(
        (c) =>
          c.compliance_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          c.process_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          c.control_name?.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      item.standards?.some((s) =>
        s.standard_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return complianceMatch && processMatch && controlMatch && standardMatch && searchMatch;
  });

  const sorted = applySortFilter(filtered, getComparator(order, orderBy));
  return {
    filteredData: sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    totalFilteredCount: filtered.length 
  };
}, [
    safeData, 
    selectedCompliance, 
    selectedProcess, 
    selectedControl, 
    selectedStandard, 
    searchTerm, 
    order, 
    orderBy, 
    page, 
    rowsPerPage
  ]);
const { filteredData, totalFilteredCount } = processedData;

  // Function to check the status of sub-artifacts and return the appropriate chip label
  const getArtifactChipLabel = (artifact) => {
    if (!artifact.SubArtifact || artifact.SubArtifact.length === 0) {
      return "Not Uploaded";
    }

    const allSubArtifactsExpired = artifact.SubArtifact.every((subArtifact) => {
      return (
        !subArtifact.artifacts ||
        subArtifact.artifacts.length === 0 ||
        subArtifact.artifacts.every((artifact) => new Date(artifact.date_of_expiry) < new Date())
      );
    });

    if (allSubArtifactsExpired) {
      return `${artifact.artifact_name}: Expired`;
    } else {
      return "Uploaded";
    }
  };
  const [SubArtifactid, setSubArtifactid] = useState({});
  const [SelectedCompliance_id, setSelectedCompliance_id] = useState({});
  const [SelectedProcess_id, setSelectedProcess_id] = useState({});
  const [SelectedControl_id, setSelectedControl_id] = useState({});
  console.log("filter", question);
  const handleOnChangecompliance = (event, value) => {
    setSelectedCompliance_id(value.id);
  };
  const handleOnChangeprocess = (event, value) => {
    setSelectedProcess_id(value.id);
  };
  const handleOnChangecontrol = (event, value) => {
    setSelectedControl_id(value.id);
  };
  const toggleDrawer1 = (newOpen) => () => {
    setOpen1(newOpen);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fileInput = React.useRef();
  const fileInput0 = React.useRef();

  const config = {
    bucketName: "whizzc-artifact",
    region: "us-east-1",
    accessKeyId: "AKIATUEBG6QGX4BZS2SE",
    secretAccessKey: "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",
    s3Url: "https://whizzc-artifact.s3.amazonaws.com",
  };
  const handleUpload = (file, artifactId) => {
    setUploading(true);
    if (!file) {
      setSnackbarMessage("No file selected. Please select a file to upload.");
      setSnackbarType("error"); 
      setOpenSnackbar(true); 
      setUploading(false);
      return;
    }

    const filePath = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const contentType = file.type || "application/octet-stream";
  
    const apiUrl =
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/generate-presigned-urls/";

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookie.load("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify({
          artifact_id: artifactId,
          files: [
            {
              file_path: filePath,
              content_type: contentType,
            },
          ],
        }),
      })
      .then((response) => {
        if (!response.ok) 
          throw new Error("Error while uploading, please retry!");
        return response.json();
      })
      .then((data) => {
        const presignedUrl = data.presigned_urls[0]?.presigned_url;
        if (!presignedUrl) 
          throw new Error("Failed to upload!");

        return fetch(presignedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": contentType,
          },
          body: file,
        }).then((uploadResponse) => {
          if (!uploadResponse.ok) 
            throw new Error("Failed to upload file.");

          const uploadedFileUrl = presignedUrl.split("?")[0];
          setS3filelink(uploadedFileUrl);
          setUploading(false);
          setSnackbarMessage("File uploaded successfully!");
          setSnackbarType("success");
          setOpenSnackbar(true);
        });
      })
      .catch((error) => {
        setUploading("error");
        setSnackbarMessage(error.message || "An unexpected error occurred.");
        setSnackbarType("error"); 
        setOpenSnackbar(true); 
      }
    );
  };
  const config0 = {
    bucketName: "whizzc-artifact",
    // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: "us-east-1",
    accessKeyId: "AKIATUEBG6QGX4BZS2SE",
    secretAccessKey: "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",
    s3Url: "https://whizzc-artifact.s3.amazonaws.com",
  };

  const renderFileViewer = (link) => {
    const mainPart = link.split("?AWSAccessKeyId")[0];
    const fileExtension = mainPart.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            link
          )}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };
  const handleUpload0 = (file, artifactId) => {
    setUploading(true);
    if (!file) {
      setSnackbarMessage("No file selected. Please select a file to upload.");
      setSnackbarType("error"); 
      setOpenSnackbar(true); 
      setUploading(false);
      return;
    }
  
    const filePath = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const contentType = file.type || "application/octet-stream";
  
    const apiUrl =
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/generate-presigned-urls/";

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookie.load("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify({
          artifact_id: artifactId,
          files: [
            {
              file_path: filePath,
              content_type: contentType,
            },
          ],
        }),
      })
      .then((response) => {
        if (!response.ok) 
          throw new Error(" Error while uploading, please retry!");
        return response.json();
      })
      .then((data) => {
        const presignedUrl = data.presigned_urls[0]?.presigned_url;
        if (!presignedUrl) 
          throw new Error("Failed to upload file!");

        return fetch(presignedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": contentType,
          },
          body: file,
        }).then((uploadResponse) => {
          if (!uploadResponse.ok) 
            throw new Error("Failed to upload file");

          const uploadedFileUrl = presignedUrl.split("?")[0];
          setS3filelink0(uploadedFileUrl);
          setUploading(false);
          setSnackbarMessage("File uploaded successfully!");
          setSnackbarType("success");
          setOpenSnackbar(true);
        });
      })
      .catch((error) => {
        setUploading("error");
        setSnackbarMessage(error.message || "An unexpected error occurred.");
        setSnackbarType("error"); 
        setOpenSnackbar(true); 
      }
    );
  };
  const [openModal, setOpenModal] = useState(false); // To control the modal open state
  const [artifactLink, setArtifactLink] = useState(null); // To store the link of the artifact
  const [isFullscreen, setIsFullscreen] = useState(false); // To control fullscreen mode

  // Function to open the modal with the selected artifact link
  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink(null);
  };

  // Function to toggle fullscreen mode
  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };
  const getQuestion123 = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/artifactmasterlist/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserList(data.user_list);
        } else {
        }
      })
      .catch((err) => { });
  };
  const Mappinglist = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/mappinglist/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setMappind_masterdata(data);
          Loading(false);
        } else {
          Loading(false);
        }
      })
      .catch((err) => { });
  };
  const getQuestion = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/tenanatartifactlist/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setQuestion(data);
          setLoading(false);
        } else {
          setQuestion(null);
          setLoading(false);
        }
      })
      .catch((err) => { });
  };
  const getSubArtifact = (e) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/getsubartifactlist/" +
      e +
      "/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setSubArtifact(data);
          setOpen1(true);
          handlemodalOpen();
          setLoading(false);
        } else {
          setSubArtifact([]);
          Loading(false);
        }
      })
      .catch((err) => { });
  };
  const getQuestionlistartifact = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/auditmodule/listartifact/" +
      SubArtifact.sub_artifact_id +
      "/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setartifact(data);
          setLoading(false);
          handleCloseform();
        } else {
          setartifact(null);
          setLoading(false);
        }
      })
      .catch((err) => { });
  };

  useEffect(async () => {
    getQuestion();
    getQuestion123();
    Mappinglist();
  }, []);
  let finalAns = Object.values(
    checkList.reduce(
      (acc, cur) => Object.assign(acc, { [cur.question_id]: cur }),
      {}
    )
  );

  const onClickshow = (e, f) => {
    if (f === openid) {
      setopenid(0);
    } else {
      setopenid(f);
    }
  };

  const parentFunction = (points, audit_header_id, audit_gap_id) => {
    setLoading(true);
    const requestOption = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        user: points,
        modified_by: decryption(cookie.load("user")),
      }),
    };

    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/auditmodule/assignartifactuser/" +
      audit_header_id +
      "/" +
      audit_gap_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          getQuestion();
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => { }
    );
  };

  const handleSubmit11 = (event) => {
    event.preventDefault();
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",

      body: JSON.stringify({
        artifact_path: S3filelink,
        artifact_uri: null,
        date_of_capture: event.target.eve_capture.value,
        date_of_expiry: event.target.eve_end.value,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/adddocument/" +
      SubArtifactid +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201) {
          res.json().then((json) => { });
          handleCloseform(false);
          handleCloseform0(false);
          getSubArtifact(tenant_artifact_id);
          setS3filelink(null);
        } else {
          res.json().then((json) => { });
          setLoading(false);
        }
      },
      (error) => { }
    );
  };
  const handleSubmit121 = (event) => {
    event.preventDefault();
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        is_document_added: S3filelink0 ? true : false,
        sub_artifact_name: event.target.Eve_name.value,
        artifact_path: S3filelink0,
        date_of_capture: event.target.date_of_capture.value,
        date_of_expiry: event.target.Expire_date.value,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/addsubartifact/" +
      tenant_artifact_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          res.json().then((json) => { });
          handleCloseform();
          handleCloseform0();
          getSubArtifact(tenant_artifact_id);
          setS3filelink0(null);
          setLoading(false);
        } else {
          res.json().then((json) => { });
          setLoading(false);
        }
      },
      (error) => { }
    );
  };

  const handleSubmit12121 = (event) => {
    setLoading(true);

    event.preventDefault();
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        is_mapping: Mapping,
        artifact_name: event.target.artifact_name.value,
        compliance_id: SelectedCompliance_id,
        process_id: SelectedProcess_id,
        control_id: SelectedControl_id,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/createcustomartifact/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201 || 200) {
          res.json().then((json) => { });
          handleCloseform();
          handleCloseform0();
          handleCloseform01();

          getQuestion();
        } else {
          res.json().then((json) => { });
          setLoading(false);
        }
      },
      (error) => { }
    );
  };
  const Assignuser = (user) => {
    console.log("op", user.user);
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",

      body: JSON.stringify({
        assigned_to: user.user,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/assigntenantartifact/" +
      tenant_artifact_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201 || 200) {
          res.json().then((json) => {
            getQuestion();
            handleClose3();
            setLoading(false);
          });
        } else {
          res.json().then((json) => {
            getQuestion();
            handleClose3();
            setLoading(false);
          });
        }
      },
      (error) => { }
    );
  };
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen = () => {
    setOpen3(true);
  };
  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };
    const navigate = useNavigate();

    const handleListItemClick = (value) => {
      onClose(value);
    };


    return (
      <Dialog style={{ left: "40%" }} onClose={handleClose} open={open}>
        <Backdrop
          sx={{
            color: "#fff",
          }}
          open={Loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Select User</DialogTitle>
        <List>
          <Box sx={{ pt: 0, height: 400, width: 300, overflow: "scroll" }}>
            {userList.map((user) => (
              <ListItem disableGutters key={user}>
                <ListItemButton onClick={() => Assignuser(user)}>
                  <ListItemAvatar>
                    <Avatar
                      alt={user.profile_name}
                      // src="/static/images/avatar/1.jpg"
                      sx={{ bgcolor: blue[100], color: blue[600] }}
                    >
                      {/* <PersonIcon /> */}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.profile_name} />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <ListItem disableGutters>
            <ListItemButton autoFocus onClick={() => navigate("/user&roles")}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add User" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  }
  const handleClose3 = (value) => {
    setOpen3(false);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 500,
      },
    },
  };
  return (
    <div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SimpleDialog open={open3} onClose={handleClose3} />
      <div style={{ background: "#eeeeee", padding: "10px" }}>
        <div
          style={{
            height: height - 170,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Modal
            open={openform01}
            onClose={handleCloseform01}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <Box sx={style1}>
                <div style={{ color: "#000" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                      cookie.load("Language")
                    ] || "Add Directory"}
                  </Typography>
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    onSubmit={handleSubmit12121}
                    className="upload-steps"
                  >
                    <TextField
                      name="artifact_name"
                      onChange={(e) => {
                        setEveidanceName0(e.target.value);
                      }}
                      id="standard-basic"
                      label={
                        { ar: "اسم الأدلة", sp: "Spanish" }[
                        cookie.load("Language")
                        ] || "Directory Name"
                      }
                      variant="outlined"
                      required
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={() => {
                              setMapping(!Mapping);
                            }}
                            defaultChecked={Mapping}
                          />
                        }
                        label="Mapping"
                      />
                    </FormGroup>
                    {Mapping ? (
                      <Box
                        gap={"10px"}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        {" "}
                        <Autocomplete
                          disablePortal
                          fullWidth
                          id="combo-box-demo"
                          options={Mappind_masterdata.compliance}
                          getOptionLabel={(option) => option.label} // Use option.label to display in the input
                          onChange={handleOnChangecompliance}
                          renderInput={(params) => (
                            <TextField
                              name="map_compliance"
                              required={Mapping}
                              {...params}
                              label="Map Compliance"
                            />
                          )}
                        />
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={Mappind_masterdata.process}
                          onChange={handleOnChangeprocess}
                          renderInput={(params) => (
                            <TextField
                              name="map_process"
                              required={Mapping}
                              {...params}
                              label="Map Process"
                            />
                          )}
                        />
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={Mappind_masterdata.control}
                          onChange={handleOnChangecontrol}
                          renderInput={(params) => (
                            <TextField
                              name="map_control"
                              required={Mapping}
                              {...params}
                              label="Map Control"
                            />
                          )}
                        />
                      </Box>
                    ) : null}

                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button className="cancel-button"
                        onClick={() => {
                          setS3filelink(null);
                          handleCloseform01();
                        }}
                      //   style={{
                      //     background: "#eeeeee",
                      //     color: "#808080",
                      //     marginLeft: "10px",
                      //   }}
                      //  variant="contained"
                      >
                        {{ ar: "يلغي", sp: "Spanish" }[
                          cookie.load("Language")
                        ] || "Cancel"}
                      </Button>
                      <Button className="submit-button"
                        type="submit"
                        // style={{
                        //   background: "#00304c",
                        //   marginLeft: "10px",
                        //   color: "#ffffff",
                        // }}
                        // variant="contained"
                      >
                        {{ ar: "يُقدِّم", sp: "Spanish" }[
                          cookie.load("Language")
                        ] || "Submit"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Box>
            </Box>
          </Modal>
          <div style={{}}>
            {question ? (
              question.length === 0 ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "7%",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      color: "#001b2b",
                    }}
                  >
                    No data found !
                  </a>
                  <br />

                  <br />
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    gap={"30px"}
                  >
                    <Button
                      onClick={handleOpenform01}
                      variant="contained"
                      color="inherit"
                      sx={{ color: "#808080" }}
                    >
                      Add Custom Directory
                    </Button>
                    <Button
                      onClick={() => event.function1(0, 1)}
                      variant="contained"
                      sx={{ color: "white", background: "#001b2b" }}
                    >
                      Import From Master List
                    </Button>
                  </Box>
                  {/* Search Box */}


                </div>
              ) : (
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box height={"fit-content"}>
                    <Box
                      height={"fit-content"}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Button className="create-add-buttons"
                        onClick={handleOpenform01}
                        //   disabled
                        // variant="contained"
                        // sx={{ color: "white", background: "#001b2b" }}
                      >
                        Add Custom Directory
                      </Button>

                    </Box>
                    <br />
                    <TextField
                      sx={{ m: 1, width: '98%' }}
                      label="Search"
                      variant="outlined"
                      fullWidth
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    

                    <FormControl sx={{ m: 1, width: '20%' }}>
  <InputLabel id="compliance-select-label">Compliance Name</InputLabel>
  <Select
    labelId="compliance-select-label"
    label="Compliance Name"
    id="compliance-select"
    multiple
    value={selectedCompliance}
    onChange={(e) => {
      const value = e.target.value;
      setSelectedCompliance(typeof value === 'string' ? value.split(',') : value);
    }}
    renderValue={(selected) => selected.join(", ")}
    MenuProps={MenuProps} // Apply MenuProps here
  >
    {complianceOptions.map((name) => (
      <MenuItem key={name} value={name}>
        <Checkbox checked={selectedCompliance.includes(name)} />
        <ListItemText primary={name} />
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl sx={{ m: 1, width: '20%' }}>
  <InputLabel id="process-select-label">Process Name</InputLabel>
  <Select
    labelId="process-select-label"
    label="Process Name"
    id="process-select"
    multiple
    value={selectedProcess}
    onChange={(e) => {
      const value = e.target.value;
      setSelectedProcess(typeof value === 'string' ? value.split(',') : value);
    }}
    renderValue={(selected) => selected.join(", ")}
    MenuProps={MenuProps} // Apply MenuProps here
  >
    {processOptions.map((name) => (
      <MenuItem key={name} value={name}>
        <Checkbox checked={selectedProcess.includes(name)} />
        <ListItemText primary={name} />
      </MenuItem>
    ))}
  </Select>
</FormControl>

{/* <FormControl sx={{ m: 1, width: '20%' }}>
  <InputLabel id="control-select-label">Control Name</InputLabel>
  <Select
    labelId="control-select-label"
    label="Control Name"
    id="control-select"
    multiple
    value={selectedControl}
    onChange={(e) => {
      const value = e.target.value;
      setSelectedControl(typeof value === 'string' ? value.split(',') : value);
    }}
    renderValue={(selected) => selected.join(", ")}
    MenuProps={MenuProps} // Apply MenuProps here
  >
    {controlOptions.map((name) => (
      <MenuItem key={name} value={name}>
        <Checkbox checked={selectedControl.includes(name)} />
        <ListItemText primary={name} />
      </MenuItem>
    ))}
  </Select>
</FormControl> */}

<FormControl sx={{ m: 1, width: '30%' }}>
  <Autocomplete
    multiple
    id="standard-select"
    options={standardOptions}
    value={selectedStandard}
    onChange={(event, newValue) => setSelectedStandard(newValue)}
    disableCloseOnSelect
    getOptionLabel={(option) => option}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox sx={{ marginRight: 1 }} checked={selectedStandard.indexOf(option) > -1} />
        {option}
      </li>
    )}
    renderInput={(params) => (
      <TextField {...params} label="Standard Name" />
    )}
    PaperComponent={(props) => (
      <Paper {...props} style={{ maxHeight: 500 }} /> // Set max height for Autocomplete
    )}
  />
</FormControl>

                    {/* <Typography variant="body1" sx={{ m: 2, color: 'black', textAlign: 'right' }}>
                      Number of Artifacts Listed: {filteredData.length}
                    </Typography> */}
                    {/* <div style={{ padding: "5px", height: "fit-content" }}>
                      <div
                        className="controlslist"
                        style={{
                          borderBottom: "1px solid #eeeeee",
                          cursor: "initial",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <a
                          style={{
                            color: "#001b2b",
                            margin: "10px",
                            fontSize: "16px",
                            textAlign: "left",
                          }}
                        >
                          {{ ar: "اسم العملية", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Id"}
                        </a>
                        <a
                          style={{
                            color: "#001b2b",
                            margin: "10px",
                            fontSize: "16px",
                            textAlign: "left",
                            width: "70%",
                          }}
                        >
                          {{ ar: "اسم العملية", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Directory Name"}
                        </a>

                        <div
                          style={{ marginTop: "10px", width: "fit-content" }}
                        >
                          <a
                            style={{
                              color: "#001b2b",
                              margin: "10px",
                              fontSize: "16px",

                              overflowWrap: "unset",
                              marginRight: "25px",
                              whiteSpace: "nowrap", // Corrected property name
                            }}
                          >
                            {{ ar: "اسم العملية", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "Assign User"}
                          </a>
                        </div>


                      </div>
                    </div> */}
                  </Box>
                  <Drawer
                    sx={{
                      width: "40%",
                      maxWidth: "40%",
                      height: "100%",
                      flexShrink: 0,
                      overflow: "scroll",
                      "& .MuiDrawer-paper": {
                        width: "40%",
                        maxWidth: "40%",
                        overflow: "scroll", // Changed to hidden to manage overflow better within the drawer
                      },
                    }}
                    anchor={"right"}
                    open={modalopen}
                    onClose={handlemodalClose}
                  >
                    {" "}
                    <div
                      style={{
                        color: "#001b2b",
                        marginBottom: 20,
                        borderRadius: 5,
                        padding: 40,
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        {" "}
                        <h3>Artifacts</h3>
                        <br />

                        <Modal
                          open={openform0}
                          onClose={handleCloseform0}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box>
                            <Box sx={style12}>
                              <div style={{ color: "#000" }}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                                    cookie.load("Language")
                                  ] || "Add New Artifact"}
                                </Typography>
                                <Form
                                  onSubmit={handleSubmit121}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 20,
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    name="Eve_name"
                                    id="standard-basic"
                                    label={
                                      { ar: "اسم الأدلة", sp: "Spanish" }[
                                      cookie.load("Language")
                                      ] || "Artifact Name"
                                    }
                                    variant="outlined"
                                    required
                                  />
                                  <TextField
                                    helperText={
                                      Uploading === "error" ? (
                                        <a style={{ color: "red" }}>
                                          Error while uploading, please retry!
                                        </a>
                                      ) : Uploading ? (
                                        <a style={{ color: "orange" }}>
                                          Please wait, uploading...
                                        </a>
                                      ) : S3filelink0 ? (
                                        <a style={{ color: "green" }}>
                                          File Uploaded
                                        </a>
                                      ) : (
                                        ""
                                      )
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      handleUpload0(e.target.files[0]);
                                    }}
                                    type="file"
                                    id="standard-basic"
                                    label={
                                      { ar: "رفع ملف", sp: "Spanish" }[
                                      cookie.load("Language")
                                      ] || "Upload File"
                                    }
                                    required
                                    variant="outlined"
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "20px",
                                    }}
                                  >
                                    {" "}
                                    <TextField
                                      name="date_of_capture"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      type="date"
                                      id="standard-basic"
                                      label={
                                        { ar: "تاريخ الالتقاط", sp: "Spanish" }[
                                        cookie.load("Language")
                                        ] || "Date of Capture"
                                      }
                                      variant="outlined"
                                      required
                                    />{" "}
                                    <TextField
                                      required
                                      name="Expire_date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth
                                      type="date"
                                      id="standard-basic"
                                      label={
                                        {
                                          ar: "تاريخ انتهاء الصلاحية",
                                          sp: "Spanish",
                                        }[cookie.load("Language")] ||
                                        " Expire Date"
                                      }
                                      variant="outlined"
                                    />
                                  </Box>{" "}
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button className= "cancel-button"
                                      onClick={() => {
                                        setS3filelink(null);
                                        handleCloseform0();
                                      }}
                                      // style={{
                                      //   background: "#eeeeee",
                                      //   color: "#808080",
                                      // }}
                                      // variant="contained"
                                    >
                                      {{ ar: "يلغي", sp: "Spanish" }[
                                        cookie.load("Language")
                                      ] || "Cancel"}
                                    </Button>
                                    <Loadingbutton className="submit-button"
                                      type={!Uploading ? "submit" : "button"}
                                      loading={Loading}
                                      // style={{
                                      //   background: "#00304c",
                                      //   marginLeft: "10px",
                                      //   color: "#ffffff",
                                      // }}
                                      // variant="contained"
                                    >
                                      {{ ar: "يُقدِّم", sp: "Spanish" }[
                                        cookie.load("Language")
                                      ] || "Submit"}
                                    </Loadingbutton>
                                  </div>
                                </Form>
                              </div>
                            </Box>
                          </Box>
                        </Modal>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                          {" "}
                          <Button className= "create-add-buttons"
                            onClick={handleOpenform0}
                            // style={{
                            //   background: "#001b2b",
                            //   marginLeft: "10px",
                            //   background: "#001b2b",
                            //   color: "white",
                            // }}
                            // variant="contained"
                          >
                            {{ ar: "إضافة قطعة أثرية فرعية", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "Add Artifact"}
                          </Button>
                        </Box>
                      </Box>
                      <br />
                      {SubArtifact.length == 0 ? (
                        <div>
                          <a style={{ color: "black" }}>no data</a>
                        </div>
                      ) : (
                        <Form>
                          <Modal
                            open={openform}
                            onClose={handleCloseform}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box>
                              <Box sx={style12}>
                                <div style={{ color: "#000" }}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                                      cookie.load("Language")
                                    ] || "Add New Artifact"}
                                  </Typography>
                                  <Form
                                    onSubmit={handleSubmit11}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "20px",
                                    }}
                                  >
                                    {" "}
                                    <TextField
                                      helperText={
                                        Uploading === "error" ? (
                                          <a style={{ color: "red" }}>
                                            Error while uploading, please retry!
                                          </a>
                                        ) : Uploading ? (
                                          <a style={{ color: "orange" }}>
                                            Please wait, uploading...
                                          </a>
                                        ) : S3filelink ? (
                                          <a style={{ color: "green" }}>
                                            File Uploaded
                                          </a>
                                        ) : (
                                          ""
                                        )
                                      }
                                      onChange={(e) => {
                                        handleUpload(e.target.files[0]);
                                      }}
                                      type="file"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      id="standard-basic"
                                      label={
                                        { ar: "رفع ملف", sp: "Spanish" }[
                                        cookie.load("Language")
                                        ] || "Upload File"
                                      }
                                      variant="outlined"
                                      accept="application/pdf,application/vnd.ms-excel"
                                      required
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        fullWidth
                                        name="eve_capture"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        type="date"
                                        placeholder="dd/mm/yyyy" // added this line
                                        id="standard-basic"
                                        label={
                                          {
                                            ar: "تاريخ الالتقاط",
                                            sp: "Spanish",
                                          }[cookie.load("Language")] ||
                                          "Date of Capture"
                                        }
                                        variant="outlined"
                                        required
                                      />{" "}
                                      <TextField
                                        fullWidth
                                        required
                                        name="eve_end"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        type="date"
                                        placeholder="dd/mm/yyyy" // added this line
                                        id="standard-basic"
                                        label={
                                          {
                                            ar: "تاريخ انتهاء الصلاحية",
                                            sp: "Spanish",
                                          }[cookie.load("Language")] ||
                                          " Expire Date"
                                        }
                                        variant="outlined"
                                      />{" "}
                                    </Box>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button className="cancel-button"
                                        onClick={() => {
                                          setS3filelink(null);
                                          handleCloseform();
                                        }}
                                        // style={{
                                        //   background: "#eeeeee",
                                        //   color: "#808080",
                                        //   marginLeft: "10px",
                                        // }}
                                        // variant="contained"
                                      >
                                        {{ ar: "يلغي", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Cancel"}
                                      </Button>
                                      <Loadingbutton className="submit-button"
                                        type={!Uploading ? "submit" : ""}
                                        loading={Loading}
                                        // style={{
                                        //   background: "#00304c",
                                        //   marginLeft: "10px",
                                        //   color: "#ffffff",
                                        // }}
                                        // variant="contained"
                                      >
                                        {{ ar: "يُقدِّم", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Submit"}
                                      </Loadingbutton>
                                    </div>
                                  </Form>
                                </div>
                              </Box>
                            </Box>
                          </Modal>

                          <div>
                            {SubArtifact.map((c, i) => {
                              return (
                                <div style={{}} key={i}>
                                  <Accordion sx={{ background: "#eeeeee" }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <Badge
                                        overlap="rectangular"
                                        color="error"
                                      >
                                        <Button style={{ color: "black" }}>
                                          {" "}
                                          {i + 1} . {c.sub_artifact_name}{" "}
                                        </Button>
                                      </Badge>{" "}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Timeline
                                        sx={{
                                          [`& .${timelineOppositeContentClasses.root}`]:
                                          {
                                            flex: 0.2,
                                          },
                                        }}
                                      >
                                        <Box
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          flexDirection={"row"}
                                        >
                                          <a style={{ color: "#808080" }}>
                                            Expire Date
                                          </a>{" "}
                                          <a style={{ color: "#808080" }}>
                                            Version Number
                                          </a>{" "}
                                          <Button className="create-add-buttons"
                                            onClick={() => {
                                              handleOpenform();
                                              setSubArtifactid(
                                                c.sub_artifact_id
                                              );
                                            }}
                                            // style={{
                                            //   background: "#001b2b",
                                            //   marginLeft: "10px",
                                            // }}
                                            // variant="contained"
                                          >
                                            {{ ar: "رفع", sp: "Spanish" }[
                                              cookie.load("Language")
                                            ] || "Renew Artifact"}
                                          </Button>
                                        </Box>
                                        <br />
                                        <Box
                                          sx={{
                                            width: "100%",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {c.artifacts.map((item1, i1) => {
                                            return (
                                              <TimelineItem>
                                                <TimelineOppositeContent color="textSecondary">
                                                  {item1.date_of_expiry}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                  <TimelineDot />
                                                  <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                  <a
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                      cursor: "pointer", // Makes it clear that it's clickable
                                                    }}
                                                    onClick={() => handleOpenArtifactModal(item1.artifact_path)} // Open modal with selected file
                                                  >
                                                    {" "}
                                                    Version-{item1.version}
                                                  </a>

                                                </TimelineContent>
                                              </TimelineItem>
                                            );
                                          })}
                                        </Box>
                                      </Timeline>{" "}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              );
                            })}
                          </div>
                        </Form>
                      )}
                    </div>
                  </Drawer>
                  
                        <TableContainer>
                          <Table>
                            <TableHead
                              style={{
                                backgroundColor: "#dfdfdf",
                              }}
                            >
                              <TableRow>
                                <TableCell 
                                  sx={{
                                    color: "#001b2b",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                  }}
                                >
                                  { { ar: "معرف", sp: "Spanish" }[cookie.load("Language")] || "Id" }
                                </TableCell>
                                <TableCell sortDirection={orderBy === "artifact_name" ? order : false}
                                  sx={{
                                    color: "#001b2b",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    maxwidth: "20rem",
                                  }}
                                >
                                  <TableSortLabel
                                   active={orderBy === "artifact_name"}
                                   direction={orderBy === "artifact_name" ? order : "asc"}
                                   onClick={(event) => handleRequestSort(event, "artifact_name")}
                                  >
                                  { { ar: "اسم العملية", sp: "Spanish" }[cookie.load("Language")] || "Directory Name" }
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#001b2b",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  { { ar: "اسم العملية", sp: "Spanish" }[cookie.load("Language")] || "Assign User" }
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {filteredData.map((item, index) => (
                                <TableRow key={index} hover>
                                  <TableCell
                                    align="left"
                                    onClick={() => {
                                      getSubArtifact(item.tenant_artifact_id);
                                      settenant_artifact_id(item.tenant_artifact_id);
                                    }}
                                    sx={{
                                      color: "#001b2b",
                                      fontFamily: "'Poppins', sans-serif",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                   {page * rowsPerPage + index + 1}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    onClick={() => {
                                      getSubArtifact(item.tenant_artifact_id);
                                      settenant_artifact_id(item.tenant_artifact_id);
                                    }}
                                    sx={{
                                      color: "#808080",
                                      fontFamily: "'Poppins', sans-serif",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                    }}
                                  >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                      <span style={{ fontWeight: "bold", color: "#001b2b", fontSize: "14px" }}>
                                        {item.artifact_name}
                                      </span>
                                      <span style={{ color: "#808080", marginTop: "5px" }}>
                                        {item.artifact_description}
                                      </span>
                                    </div>
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    onClick={() => {
                                      handleClickOpen();
                                      settenant_artifact_id(item.tenant_artifact_id);
                                    }}
                                    title={
                                      item.assigned_to
                                        ? item.assigned_to
                                        : decryption(cookie.load("username"))
                                    }
                                    sx={{
                                      color: "#808080",
                                      fontFamily: "'Poppins', sans-serif",
                                      fontWeight: 400,
                                      fontSize: "14px",                 
                                    }}
                                  >
                                    <Avatar
                                      alt={
                                        item.assigned_to
                                          ? item.assigned_to
                                          : decryption(cookie.load("username"))
                                      }
                                      src="/static/images/avatar/1.jpg"
                                      sx={{
                                        bgcolor: blue[100],
                                        color: blue[600],
                                        height: "30px",
                                        width: "30px",
                                        marginLeft: "25px",
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* <div
                          className="controlslist"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        > */}
                          {/* S.No with clickable link */}
                          {/* <a
                            onClick={() => {
                              getSubArtifact(item.tenant_artifact_id);
                              settenant_artifact_id(item.tenant_artifact_id);
                            }}
                            style={{
                              color: "#001b2b",
                              margin: "10px",
                              fontSize: "16px",
                              textAlign: "left",
                              marginLeft: "40px",
                            }}
                          >
                            {index + 1}
                          </a> */}

                          {/* Artifact name */}
                          {/* <a
                            onClick={() => {
                              getSubArtifact(item.tenant_artifact_id);
                              settenant_artifact_id(item.tenant_artifact_id);
                            }}
                            style={{
                              color: "#808080",
                              margin: 10,
                              fontSize: "16px",
                              fontWeight: "550",
                              textAlign: "left",
                              width: "70%",
                            }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
  <span style={{ fontWeight: 'bold', color: '#001b2b', fontSize: '16px' }}>
    {item.artifact_name}
  </span>
  <span style={{ color: '#808080', marginTop: '5px' }}>
    {item.artifact_description}
  </span>
</div>

                          </a> */}

                          {/* Assigned user avatar */}
                          {/* <div
                            onClick={() => {
                              handleClickOpen();
                              settenant_artifact_id(item.tenant_artifact_id);
                            }}
                            title={
                              item.assigned_to
                                ? item.assigned_to
                                : decryption(cookie.load("username"))
                            }
                            style={{ marginTop: "3px", marginRight: "45px" }}
                          >
                            <Avatar
                              alt={
                                item.assigned_to
                                  ? item.assigned_to
                                  : decryption(cookie.load("username"))
                              }
                              src="/static/images/avatar/1.jpg"
                              sx={{
                                bgcolor: blue[100],
                                color: blue[600],
                                height: "30px",
                                width: "30px",
                              }}
                            ></Avatar>
                          </div>
                        </div> */}
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={totalFilteredCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />     
                </div>
              )
            ) : (
              <Box style={{ width: "100%" }}>
                <Skeleton style={{ height: "100px" }} />
                <Skeleton style={{ height: "100px" }} animation="wave" />
                <Skeleton style={{ height: "100px" }} animation={false} />
                <Skeleton style={{ height: "100px" }} />
              </Box>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isFullscreen ? "100%" : "80vw", // Control width based on fullscreen mode
            height: isFullscreen ? "100%" : "80vh", // Control height based on fullscreen mode
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            {/* Close button */}
            <IconButton
              onClick={handleCloseArtifactModal}
              style={{ background: "#eeeeee", color: "#808080" }}
            >
              <CloseIcon />
            </IconButton>

            {/* Fullscreen toggle button */}
            <IconButton
              onClick={handleToggleFullscreen}
              style={{ background: "#eeeeee", color: "#808080" }}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>

          {/* Render the file inside the modal */}
          <Box sx={{ height: "100%", width: "100%" }}>
            {artifactLink && renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Myartifacts;