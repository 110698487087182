import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  Card,
  CardContent,
  Divider,
  Drawer,
  Modal,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
  Paper,
  TablePagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import cookie from "react-cookies";
import S3 from "react-aws-s3";
import useWindowDimensions from "../../useWindowDimensions";
import "./RequirementsListing.css";

const config = {
  bucketName: "whizzc-artifact",
  region: "us-east-1",
  accessKeyId: "AKIATUEBG6QGX4BZS2SE",
  secretAccessKey: "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",
  s3Url: "https://whizzc-artifact.s3.amazonaws.com",
};

function RequirementsListing() {
  const { complianceId } = useParams();
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [subChecks, setSubChecks] = useState([]);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    sub_check_name: "",
    artifact_path: "",
    comment: "",
    date_of_expiry: "",
  });
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [artifactLink, setArtifactLink] = useState("");
  const [selectedSubCheck, setSelectedSubCheck] = useState(null);
  const [artifactFormVisible, setArtifactFormVisible] = useState(null); // New state to track which sub-check's form is open
  const fileInput = useRef(null);
  const { height, width } = useWindowDimensions();
  const [companyName, setCompanyName] = useState(""); // State to store company name
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [checks, setChecks] = useState([]);
  const [artifactList, setArtifactList] = useState([]);
  const [selectedSubCheckId, setSelectedSubCheckId] = useState(null);
  const [expandedSubCheck, setExpandedSubCheck] = useState(null);
  const [subcheckLoading, setSubcheckLoading] = useState(false);
  const [artifactLoading, setArtifactLoading] = useState(false);

  useEffect(() => {
    fetchRequirements();
  }, [complianceId]);

  const fetchRequirements = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/artifact/splitrequirement/${complianceId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setRequirements(data.requirement_list || []);
      setCompanyName(data.details.company_name); // Set the company name from API response

      console.log("Requirements data:", data);
    } catch (error) {
      console.error("Failed to fetch requirements", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChecks = async (requirement_id) => {
    setChecks([]);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/artifact/splitchecklist/${complianceId}/${requirement_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setChecks(data);
    } catch (error) {
      console.error("Failed to fetch checks", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubChecks = async (check_id) => {
    setSubcheckLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/splitsubchecklist/${check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setSubChecks(data || []);
      console.log("Sub-checks data for check_id", check_id, ":", data);
    } catch (error) {
      console.error("Failed to fetch subchecks", error);
    } finally {
      setSubcheckLoading(false);
    }
  };

  const fetchArtifactList = async (sub_check_id) => {
    setArtifactList([]);
    setArtifactLoading(true);
    try {
      setSelectedSubCheckId(sub_check_id);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/splitartifactlist/${sub_check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setArtifactList(data);
    } catch (error) {
      console.error("Failed to fetch Artifact", error);
    } finally {
      setArtifactLoading(false); 
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenDialog = (id) => {
    setSelectedRequirementId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      fetchChecks(panel); 
    }
  };

  const handleSubCheckChange = (subCheckId) => (event, isExpanded) => {
    setExpandedSubCheck(isExpanded ? subCheckId : null); 
    if (isExpanded) {
      fetchArtifactList(subCheckId); 
    }
  };

  useEffect(() => {
    if (!drawerOpen) {
      setExpandedSubCheck(null); 
    }
  }, [drawerOpen]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDrawerOpen = (check) => {
    setSelectedCheck(check);
    fetchSubChecks(check.check_id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSubChecks([]);
    setArtifactFormVisible(null); // Reset the artifact form visibility state
  };

  const handleUploadButtonClick = () => {
    setUploadFormOpen(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileUpload = async (file) => {
    setUploading(true);
    const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const ReactS3Client = new S3(config);

    try {
      const data = await ReactS3Client.uploadFile(file, newFileName);
      if (data.status === 204) {
        setFormValues({
          ...formValues,
          artifact_path: data.location,
        });
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("File upload error", error);
    } finally {
      setUploading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      ...formValues,
      is_document_added: formValues.artifact_path ? true : false,
      date_of_capture: dateOfCapture,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/addsubcheck/${
          selectedCheck.check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setUploadFormOpen(false);
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
      } else {
        console.error("Failed to upload sub-check");
      }
    } catch (error) {
      console.error("Failed to upload sub-check", error);
    }
  };

  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink("");
    setIsFullscreen(false);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleSubCheckFormSubmit = async (event) => {
    event.preventDefault();

    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      artifact_path: formValues.artifact_path,
      comment: formValues.comment,
      date_of_capture: dateOfCapture,
      date_of_expiry: formValues.date_of_expiry,
      artifact_uri: null,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/adddocument/${
          selectedSubCheck.sub_check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setSelectedSubCheck(null);
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
        setArtifactFormVisible(null); // Reset the artifact form visibility state
      } else {
        console.error("Failed to upload document");
      }
    } catch (error) {
      console.error("Failed to upload document", error);
    }
  };

  const renderFileViewer = (link) => {
    const mainPart = link.split("?AWSAccessKeyId")[0];

    const fileExtension = mainPart.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            link
          )}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };

  const minExpiryDate = new Date();
  minExpiryDate.setDate(minExpiryDate.getDate() + 1);

  const categoryNames = [
    ...new Set(requirements.map((item) => item.category_name).filter(Boolean)),
  ];

  const standardNames = [
    ...new Set(requirements.flatMap((item) => item.standards).filter(Boolean)),
  ];

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === "string" ? value.split(",") : value);
  };

  const handleStandardsChange = (event, newValue) => {
    setSelectedStandards(newValue);
  };

  const filteredRequirements = requirements.filter((requirement) => {
    const matchesCategory =
      selectedCategories.length > 0
        ? selectedCategories.includes(requirement.category_name)
        : true;

    const matchesSearch = requirement.requirement_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const matchesStandard =
      selectedStandards.length > 0
        ? requirement.standards.some((standard) =>
            selectedStandards.includes(standard)
          )
        : true;

    return matchesCategory && matchesSearch && matchesStandard;
  });

  const displayedRequirements = filteredRequirements.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="main-div">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || subcheckLoading || artifactLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>



      <Box className="search-box">
        <TextField className="textfield-search"
          label="Search Requirements"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
        />
      </Box>

      <Box className="filter-box">
  {/* Category Filter */}
  <FormControl className="formcontrol-1">
    <InputLabel id="category-select-label">Category</InputLabel>
    <Select
      labelId="category-select-label"
      id="category-select"
      multiple
      label="Category"
      value={selectedCategories}
      onChange={handleCategoryChange}
      renderValue={(selected) => selected.join(", ")}
    >
      {categoryNames.map((category) => (
        <MenuItem key={category} value={category}>
          <Checkbox checked={selectedCategories.indexOf(category) > -1} />
          <ListItemText primary={category} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Standard Filter */}
  <FormControl className="formcontrol-2">
  <Autocomplete
      multiple
      id="standard-select"
      options={standardNames}
      value={selectedStandards}
      onChange={handleStandardsChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox className="checkbox-standard" checked={selectedStandards.indexOf(option) > -1} />
          {option}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Standard Name" />}
      PaperComponent={(props) => <Paper {...props} style={{ maxHeight: 500 }} />}
    />
  </FormControl>
</Box>

      {displayedRequirements.length ? (
        displayedRequirements.map((requirement) => {
          const modifiedDescription =
            requirement.requirement_description.replace(
              "{Company Name}",
              companyName
            );
          const isCurrentExpanded = expanded === requirement.requirement_id;
          return (
            <Accordion className="req-accordion"
              key={requirement.requirement_id}
              expanded={expanded === requirement.requirement_id}
              onChange={handleChange(requirement.requirement_id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography className="typography-1">{requirement.requirement_name}</Typography>
                  <Typography className="typography-1" variant="body2" color="textSecondary">
                    {modifiedDescription}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className="accord-details-1">
                {isCurrentExpanded && loading ? (
              <Typography className="typography-1">Loading checks...</Typography> 
            ) : checks.length ? (
                  checks.map((check, index) => (
                    <Box className="check-box-1" key={check.check_id}>
                      <Box className="check-box-2">
                        <Box>
                          <Typography className="typography-1">{`Check ${index + 1}: ${check.check_name}`}</Typography>
                        </Box>
                        <Button className="action-button"
                          onClick={() => handleDrawerOpen(check)}
                        >
                          Action
                        </Button>
                      </Box>
                      <Divider className="check-divider"/>
                    </Box>
                  ))
                ) : (
                  <Typography className="typography-1">No checks found.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Typography className="req-typography-2">No requirements found.</Typography>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Requirement</DialogTitle>
      </Dialog>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={filteredRequirements.length} 
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "40%",
            padding: "20px",
          },
        }}
      >
        {selectedCheck && (
          <Box>
            <Typography className="typography-1" variant="h6">{selectedCheck.check_name}</Typography>
            <Typography className="typography-1" variant="body1" gutterBottom>
              {selectedCheck.check_description}
            </Typography>
            <Button className="action-button"
              onClick={handleUploadButtonClick}
            >
              Upload
            </Button>
            <Modal
              open={uploadFormOpen}
              onClose={() => {    
                setUploadFormOpen(false); 
                setFormValues({
                  sub_check_name: '',
                  comment: '',
                  date_of_expiry: '',
                });
                fileInput.current.value = null;
              }}
              aria-labelledby="upload-form-title"
              aria-describedby="upload-form-description"
            >
              <Box 
                component="form"
                onSubmit={handleFormSubmit}
                className="popup-box"
              >
                <Typography className="artifact-typography">
                  Upload Artifact
                </Typography>
                <TextField
                  name="sub_check_name"
                  label="Sub Check Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={formValues.sub_check_name}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  type="file"
                  inputRef={fileInput}
                  fullWidth
                  required
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  sx={{ mb: 2 }}
                />
                <TextField
                  name="comment"
                  label="Comment"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formValues.comment}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  name="date_of_expiry"
                  label="Date of Expiry"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: minExpiryDate.toISOString().split("T")[0],
                  }}
                  fullWidth
                  required
                  value={formValues.date_of_expiry}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <Box className="popup-box-2">
                  <Button className="popup-cancel-button"
                    onClick={() => {
                      setUploadFormOpen(false);
                      setFormValues({
                        sub_check_name: '',
                        comment: '',
                        date_of_expiry: '',
                      });          
                      fileInput.current.value = null; 
                    }}
                  >
                    Cancel
                  </Button>
                  <Button className="popup-submit-button"
                    type="submit"
                    disabled={uploading}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Typography className="typography-2" variant="h6">
              Artifacts
            </Typography>
            {subChecks.length ? (
              subChecks.map((subCheck, index) => (
              <Accordion className="subcheck-accordion"
                key={subCheck.sub_check_id}
                expanded={expandedSubCheck === subCheck.sub_check_id}
                onChange={handleSubCheckChange(subCheck.sub_check_id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box>
                    <Typography className="subcheck-typography">{`Sub Check ${index + 1}: ${subCheck.sub_check_name}`}</Typography>
                  </Box>
                </AccordionSummary>
    
                {expandedSubCheck === subCheck.sub_check_id && (
                <AccordionDetails className="accord-details-2">
                  <Button className="action-button"
                    onClick={() => {
                      setSelectedSubCheck(subCheck);
                      setArtifactFormVisible(subCheck.sub_check_id); 
                    }}
                  >
                    Renew
                  </Button>
                  {artifactLoading ? (
                    <Typography className="artifact-typography-2">
                      Artifact is loading...</Typography> 
                  ) : artifactList.length ? (
                    artifactList.map((artifact, artIndex) => (
                      <Box
                        className="artifact-box"
                        key={artifact.artifact_id}
                      >
                        <Typography className="typography-1" variant="body2" color="textSecondary">
                          Comment: {artifact.comment || "No comment"}
                        </Typography>
                        <Typography className="typography-1" variant="body2" color="textSecondary">
                          Date of Capture:{" "}
                          {new Date(
                            artifact.date_of_capture
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                        <Typography className="typography-1" variant="body2" color="textSecondary">
                          Date of Expiry:{" "}
                          {new Date(
                            artifact.date_of_expiry
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                        <Button className="open-artifact-button"
                          variant="outlined"
                          onClick={() =>
                            handleOpenArtifactModal(artifact.artifact_path)
                          }
                        >
                          Open
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Typography className="typography-1">
                      No versions found for this artifact.
                    </Typography>
                  )}
                  <Modal
                    open={artifactFormVisible === subCheck.sub_check_id}
                    onClose={() => {
                      setArtifactFormVisible(null);
                      setFormValues({
                        comment: '',
                        date_of_expiry: '',
                      }); 
                      fileInput.current.value = null; 
                    }}
                    aria-labelledby="renew-form-title"
                    aria-describedby="renew-form-description"
                  >
                    <Box className="popup-box-3"
                      component="form"
                      onSubmit={handleSubCheckFormSubmit}
                    >
                      <Typography className="renew-artifact-typo">
                        Renew Artifact
                      </Typography>
                      <TextField
                        type="file"
                        inputRef={fileInput}
                        fullWidth
                        required
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="comment"
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={formValues.comment}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="date_of_expiry"
                        label="Date of Expiry"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: minExpiryDate.toISOString().split("T")[0],
                        }}
                        fullWidth
                        required
                        value={formValues.date_of_expiry}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className="popup-cancel-button"
                          onClick={() => {
                            setArtifactFormVisible(null); 
                            setFormValues({
                              comment: '',
                              date_of_expiry: '',
                            });
                            fileInput.current.value = null;
                          }}
                        >
                          Cancel
                        </Button>
                        <Button className="popup-submit-button"
                          type="submit"
                          disabled={uploading}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                  </AccordionDetails>
                  )}
                </Accordion>
              ))
            ) : (
              <Typography className="typography-1">No sub checks found.</Typography>
            )}
          </Box>
        )}
      </Drawer>

      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
      >
        <Box className="iframe-box-1"
          sx={{
            width: isFullscreen ? "100%" : width * 0.8,
            height: isFullscreen ? "100%" : height * 0.8,
          }}
        >
          <Box className="iframe-box-2">
            <IconButton className="icon-button-1"
              onClick={handleCloseArtifactModal}
            >
              <CloseIcon />
            </IconButton>
            <IconButton className="icon-button-1"
              onClick={handleToggleFullscreen}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
          <Box className="iframe-box-3">
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default RequirementsListing;
