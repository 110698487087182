import React, { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { styled } from "@mui/material/styles";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  Drawer,
  Chip,
  InputLabel,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Skeleton,
} from "@mui/material";
import {
  UserListToolbar,
} from "../pages/@DashboardComponents/Components/PeopleComponent/user";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cookie from "react-cookies";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import "../ButtonStyles/AppStyles.css";

const StyledContent = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  minHeight: "100vh",
  padding: theme.spacing(1 , 2),
  marginBottom: theme.spacing(1),
  color: "black",
}));

// const SearchBox = styled("div")(({ theme }) => ({
//   marginBottom: theme.spacing(3),
//   display: "flex",
//   justifyContent: "left",
//   width: "100%",
// }));

// const TopRightButton = styled("div")(({ theme }) => ({
//   display: "flex",
//   justifyContent: "space-between",
//   marginBottom: theme.spacing(2),
// }));

const CategoryChips = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const FullWidthAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const RiskAssessments = () => {
  ReactSession.set("PageTitle", "RiskAssessments");

  const navigate = useNavigate();
  const [complianceList, setComplianceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredComplianceList, setFilteredComplianceList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");  
  const [orderBy, setOrderBy] = useState("risk_assessment_name"); 
  const [page, setPage] = useState(0);   
  const [categories, setCategories] = useState([]);
  const [newRiskAssessment, setNewRiskAssessment] = useState({
    category_ids: [],
    risk_assessment_name: "",
    risk_assessment_type: "",
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedList = applySortFilter(
    complianceList,
    getComparator(order, orderBy),
    searchTerm 
  );

  const sortedAndPaginatedList = complianceList
    .filter((compliance) =>
    compliance.risk_assessment_name.toLowerCase().includes(searchTerm.toLowerCase())
  )
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedList.length) : 0;

  const subdomain = window.location.host.split(".")[0];

  const fetchComplianceList = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/riskassessment/`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("Compliance List:", data);
          setComplianceList(data);
          setFilteredComplianceList(data);
          setLoading(false);
        } else {
          setComplianceList([]);
          setFilteredComplianceList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error fetching the compliance list:", err);
        setLoading(false);
      });
  };

  const fetchCategories = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/categories/`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("Categories:", data);
          setCategories(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
      });
  };

  useEffect(() => {
    fetchComplianceList();
    fetchCategories();
  }, []);

  const handleViewClick = (riskAssessment) => {
    navigate(`/RiskRequirements/${riskAssessment.risk_assessment_id}`, {
      state: riskAssessment,
    });
  };

  const handleCreateNewClick = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRiskAssessment({
      ...newRiskAssessment,
      [name]: value,
    });
  };

  const handleCategoryClick = (categoryId) => {
    console.log("Category Id :", categoryId);
    setNewRiskAssessment((prevState) => {
      const category_ids = prevState.category_ids.includes(categoryId)
        ? prevState.category_ids.filter((id) => id !== categoryId)
        : [...prevState.category_ids, categoryId];
      return {
        ...prevState,
        category_ids,
      };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const csrfToken = cookie.load("csrftoken"); // Ensure this is the correct name for your CSRF token cookie

    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/riskassessment/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(newRiskAssessment),
      }
    )
      .then((res) => {
        if (res.status === 403) {
          throw new Error("Forbidden");
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          console.log("New Risk Assessment Created:", data);
          fetchComplianceList(); // Refresh the compliance list
          setDrawerOpen(false); // Close the drawer
          setNewRiskAssessment({
            category_ids: [],
            risk_assessment_name: "",
            risk_assessment_type: "",
          });
        }
      })
      .catch((err) => {
        console.error("Error creating new risk assessment:", err);
      });
  };

  return (
    <Box>
       {loading ? (
           <Box style={{ padding: 30, width: "100%", marginTop: "2" }}>
           <Skeleton variant="rectangular" height={50} width="20%"  sx={{ marginBottom: 5 }} />
           <Skeleton style={{ height: "70px", width: "100%" }} animation="wave" />
           <Skeleton style={{ height: "100px" }} animation={false}  />
           <Skeleton style={{ height: "100px" }} />
           <Skeleton style={{ height: "100px" }} animation="wave" />
           <Skeleton style={{ height: "100px" }} animation={false} />
           <Skeleton style={{ height: "100px" }} />
           <Skeleton style={{ height: "100px" }} animation="wave" />
           </Box>
      ) : (
      <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
        <UserListToolbar
              Search_bar_name={
              { ar: "بحث عن التقييمات", sp: "Spanish" }[
                cookie.load("Language")
              ] || "Search Assessment"
            }
              filterName={searchTerm} 
              onFilterName={(e) => setSearchTerm(e.target.value)} 
            />
             {/* // <Grid item xs={12} key={compliance.risk_assessment_id}>
                //   <FullWidthAccordion>
                //     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                //       <Typography>{compliance.risk_assessment_name}</Typography>
                //     </AccordionSummary>
                //     <AccordionDetails>
                //       <Typography
                //         variant="body2"
                //         color="text.secondary"
                //         style={{ color: "black" }}
                //       >
                //         Type: {compliance.risk_assessment_type}
                //       </Typography>
                //       <Typography
                //         variant="body2"
                //         color="text.secondary"
                //         style={{ color: "black" }}
                //       >
                //         Created On:{" "}
                //         {new Date(compliance.created_on).toLocaleDateString()}
                //       </Typography>
                //       <Box
                //         sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                //       >
                //         <Button
                //           variant="contained"
                //           color="primary"
                //           onClick={() => handleViewClick(compliance)}
                //         >
                //           View
                //         </Button>
                //       </Box>
                //     </AccordionDetails>
                //   </FullWidthAccordion>
                // </Grid> */}  
        <Box
          sx={{
            marginTop: 3,
            marginRight: 2,
          }}
        >
          <Button className="create-add-buttons"
           // variant="contained"
           // sx={{ color: "#fff", background: "#001b2b" }}
            // endIcon={<AddIcon />}
            onClick={handleCreateNewClick}
            //color="primary"
            aria-label="add"
          >
            {" "}
            {{ ar: "إنشاء جديد", sp: "Spanish" }[
              cookie.load("Language")
            ] || "Create New"}
          </Button>
        </Box>
      </div>
    </div>   
    <StyledContent>
      <TableContainer>
          <Table>
            <TableHead 
              style={{
                 backgroundColor: "#dfdfdf",
              }}>
              <TableRow>
                <TableCell sortDirection={orderBy === "risk_assessment_name" ? order : false}
                  sx={{
                      color: "#001b2b",               
                      fontFamily: "'Poppins', sans-serif", 
                      fontWeight: 500,
                      fontSize: "14px", 
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "risk_assessment_name"}
                    direction={orderBy === "risk_assessment_name" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "risk_assessment_name")}
                  >
                   {
                      { ar: "اسم التقييم", sp: "spanish" }[
                          cookie.load("Language")
                      ] || "Assessment Name"
                   }
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === "risk_assessment_type" ? order : false}
                  sx={{
                    color: "#001b2b",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 500,
                    fontSize: "14px", 
                  }}  
                >
                  <TableSortLabel
                    active={orderBy === "risk_assessment_type"}
                    direction={orderBy === "risk_assessment_type" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "risk_assessment_type")}
                  >
                    {
                      { ar: "نوع التقييم", sp: "spanish" }[
                          cookie.load("Language")
                      ] || "Assessment Type"
                    }
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === "created_on" ? order : false}
                  sx={{
                    color: "#001b2b",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 500,
                    fontSize: "14px", 
                  }}  
                >
                  <TableSortLabel
                    active={orderBy === "created_on"}
                    direction={orderBy === "created_on" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "created_on")}
                  >
                   {
                      { ar: "تاريخ الإنشاء", sp:  "spanish" }[
                          cookie.load("Language")
                      ] || "Created On"
                   }
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    color: "#001b2b",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 500,
                    fontSize: "14px", 
                  }}  
                >
                {
                    { ar: "عمل", sp: "spanish" }[
                        cookie.load("Language")
                    ] || "Action"
                }
                </TableCell>
              </TableRow>
            </TableHead>

          <TableBody>
            {sortedAndPaginatedList.map((compliance) => (
              <TableRow key={compliance.risk_assessment_id}  hover>
                <TableCell align="left"
                  sx={{
                    color: "#808080",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 400,    
                    fontSize: "14px", 
                    maxWidth: "27rem", 
                    wordBreak: "break-word", 
                    whiteSpace: "normal",   
                  }}
                >
                  {compliance.risk_assessment_name}
                </TableCell>
                  <TableCell align="left"
                  sx={{
                    color: "#808080",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 400,  
                    fontSize: "14px",               
                  }}
                >
                  {compliance.risk_assessment_type}
                </TableCell>
                <TableCell align="left"
                  sx={{
                    color: "#808080",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 400,  
                    fontSize: "14px",                   
                  }}
                >
                  {new Date(compliance.created_on).toLocaleDateString()}
                </TableCell>
                <TableCell align="left"
                  sx={{
                    color: "#808080",               
                    fontFamily: "'Poppins', sans-serif", 
                    fontWeight: 400, 
                    fontSize: "14px",                    
                  }}
                >
                  <Button
                    onClick={() => handleViewClick(compliance)}
                    size="small"
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "'Poppins', sans-serif", 
                      color: "primary", 
                      border: "1px solid",
                      fontWeight: 400,
                      borderColor: "primary",
                      fontSize: "15px",
                      padding: "6px 16px",
                    }}
                  >
                   {" "}
                {{ ar: "عرض", sp: "Spanish" }[
                  cookie.load("Language")
                ] || "VIEW"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer> 
   </StyledContent>  
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 350, p: 3 }}>
          <Typography variant="h6" component="div" gutterBottom>
            {
              { ar: "إنشاء تقييم مخاطر جديد", sp: "spanish" }[
                  cookie.load("Language")
              ] || "Create New Risk Assessment"
            }
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <CategoryChips>
              {categories.map((category) => (
                <Chip
                  key={category.category_id}
                  label={category.category_name}
                  clickable
                  color={
                    newRiskAssessment.category_ids.includes(
                      category.category_id
                    )
                      ? "primary"
                      : "default"
                  }
                  onClick={() => handleCategoryClick(category.category_id)}
                />
              ))}
            </CategoryChips>
            <TextField
              fullWidth
              margin="normal"
              label={
                { ar: "اسم تقييم المخاطر", sp: "spanish" }[
                    cookie.load("Language")
                ] || "Name of Risk Assessment"
              }
              variant="outlined"
              name="risk_assessment_name"
              value={newRiskAssessment.risk_assessment_name}
              onChange={handleInputChange}
            />
            <FormControl fullWidth margin="normal">
            <InputLabel id="risk-type-label">
              {
                  { ar: "النوع", sp: "spanish" }[
                      cookie.load("Language")
                  ] || "Type"
              }
             </InputLabel>
              <Select
                labelId="risk-type-label"
                name="risk_assessment_type"
                value={newRiskAssessment.risk_assessment_type}
                onChange={handleInputChange}
                label={
                    { ar: "النوع", sp: "spanish" }[
                        cookie.load("Language")
                    ] || "Type"
                }
              >
                <MenuItem value="SELF">SELF</MenuItem>
                <MenuItem value="AUDITOR">AUDITOR</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button className="create-add-buttons" type="submit" 
                //variant="contained" sx={{backgroundColor: "#001b2b", color: "white"}}
              >
              {
                  { ar: "إرسال", sp: "spanish" }[
                      cookie.load("Language")
                  ] || "Submit"
              }
              </Button>
            </Box>
          </form>
        </Box>
       </Drawer>        
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={sortedList.length}  
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />     
     </>
    )} 
  </Box>
  );
};

export default RiskAssessments;
