import { ReactSession } from "react-client-session";
import "../components/header.css";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@mui/icons-material/Save";
import { filter } from "lodash";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import sampleFile from "../img/vendor_template.xlsx";
import cookie from "react-cookies";
import {Snackbar} from '@mui/material';
import { handleError } from '../utils/errorHandler';
import "../ButtonStyles/AppStyles.css";
import { decryption, encryption } from "../auth/utilities";

// @mui
import {
  Table,
  Stack,
  Paper,
  Button,
  FormControlLabel,
  TableRow,
  Switch,
  styled,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  TextField,
  Drawer,
  Collapse,
  Alert,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel
} from "@mui/material";
// components
import Label from "../pages/@DashboardComponents/Components/PeopleComponent/label";
import Iconify from "../pages/@DashboardComponents/Components/iconify";
import CancelIcon from "@mui/icons-material/Cancel";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
// sections
import {
  UserListHead,
  UserListToolbar,
} from "../pages/@DashboardComponents/Components/PeopleComponent/user";
// import { Data } from "./People/previewtabledata";
import * as XLSX from "xlsx";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  overflow: "hidden",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
const TABLE_HEAD = [
  {
    id: "id",
    label:
      { ar: "معرّف البائع", sp: "Spanish" }[cookie.load("Language")] ||
      "Vendor Id",
    alignRight: false,
  },
  {
    id: "name",
    label: { ar: "اسم البائع", sp: "Spanish" }[cookie.load("Language")] || "Vendor Name",
    alignRight: false,
  },
  {
    id: "vendorType",
    label:
      { ar: "نوع البائع", sp: "Spanish" }[cookie.load("Language")] || "Vendor Type",
    alignRight: false,
  },
  {
    id: "vendorScore",
    label:
      { ar: "درجة البائع", sp: "Spanish" }[cookie.load("Language")] ||
      "Vendor Score",
    alignRight: false,
  },
  {
    id: "criticality",
    label:
      { ar: "أهمية", sp: "Spanish" }[cookie.load("Language")] ||
      "Criticality",
    alignRight: false,
  },
];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  quantityRoot: {
    color: "#FFFFFF",
    backgroundColor: "#fff",
    opacity: 0.6,
    borderRadius: "5px",

    "&:hover": {
      backgroundColor: "#eeeeee",
      borderRadius: "5px",
      opacity: 1,
    },
    "&:focus-within": {
      backgroundColor: "#eeeeee",
      borderRadius: "5px",
      opacity: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #eeeeee",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #eeeeee",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #eeeeee",
      borderRadius: "5px 5px 0 0",
    },
    "& .Mui-disabled": {
      color: "#FFFFFF",
      opacity: 0.6,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #eeeeee",
    },
  },
  selectRoot: {
    color: "#000",
  },
  icon: {
    color: "#000",
  },
  selectPaper: {
    backgroundColor: "#eeeeee",
    border: "1px solid #eeeeee",
    borderRadius: "5px",
    color: "#000",
    "& li:hover": {
      backgroundColor: "#eeeeee",
    },
  },
}));
function Vendor() {
  ReactSession.set("PageTitle", "Vendor");
  const classes = useStyles();
  const subdomain = window.location.host.split(".")[0];
  const [opensnackbar, setopensnackbar] = React.useState({
    open: false,
    message: "",
  });
  const [click, setClick] = useState(false);
  const [openid, setopenid] = useState(false);
  const handleClick = () => setClick(!click);
  const [setactive, setSetactive] = useState(3);
  const [loadingVendor, setLoadingVendor] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingcsv, setLoadingcsv] = useState(false);
  const [vendorLists, setVendorLists] = useState([0]);
  const [postVendor_id, setPostVendor_id] = useState("");
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  // const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [deleteedit_id, setdeleteedit_id] = useState("");
  const [Openemail, setOpenemail] = useState(null);
  const [openname, setOPenname] = useState(null);
  const [openmodel, setOpenmodel] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(""); 
  const [open12, setOpen12] = React.useState(false);
  const [open123, setOpen123] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewdetails_data, setViewdetails_data] = useState([]); // Function to toggle the checked state
  const snackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMsg("");
  };
  const toggleDrawer1 = (newOpen) => () => {
    setOpen12(newOpen);
    setVendorName('');
    setAddress('');
    setWebsite('');
    setContactName('');
    setEmail('');
    setVendorType('');
    setNameError('');
    setContactError('');
    setEmailError('');
    setVendorType('');
    setVendorTypeError('');
    setWebsiteError('');
  };
  const toggleDrawer12 = (newOpen) => () => {
    setOpen123(newOpen);
    setopensnackbar({ open: false, message: "" });
    setUpdateNameError('');
    setUpdateContactError('');
    setupdateEmailError('');
    setUpdateDateError('');
    setUpdateWebsiteError('');
  };
  const handleOpenmodel = () => {
    setOpenmodel(true);
  };

  const handleClosemodel = () => {
    setOpenmodel(false);
  };
  const currentDate = new Date(); // Current date
  const vendorList = vendorLists.map((_, index) => ({
    vendor_no: _.vendor_no,
    avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
    vendor_name: _.vendor_name,
    vendor_type: _.vendor_type,
    vendor_score: _.vendor_score,
    criticality: _.criticality,
    vendor_dbid: _.vendor_id,
    address: _.address,
    website: _.website,
    contact_person: _.contact_person,
    contact_email: _.contact_email,
    contract_start_date: _.contract_start_date,
    contract_end_date: _.contract_end_date,
  }));

  const formatExcelDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
  
    let total_seconds = Math.floor(86400 * fractional_day);
  
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
  
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
  
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toLocaleDateString();
  };

  const Data = ({ excelData }) => {
    return (
      <>
        {excelData.map((row, index) => (
          <tr key={index}>
            <td>{row["Vendor Name"]}</td>
            <td>{row["Vendor Type"]}</td>
            <td>{row["Vendor Address"]}</td>
            <td>{row["Vendor Website"]}</td>
            <td>{row["Contact Person"]}</td>
            <td>{row["Contact Email"]}</td>
            <td>{formatExcelDate(row["Contract Start Date"])}</td>
            <td>{formatExcelDate(row["Contract End Date"])}</td>
          </tr>
        ))}
      </>
    );
  };
  const [focusField, setFocusField] = useState(null);

  const [vendorType, setVendorType] = useState('');
  const [vendorTypeError, setVendorTypeError] = useState("");
  
  const handleVendorTypeChange = (e) => {
    const value = e.target.value;
    setVendorType(value);
    
    if (!value) { 
      setVendorTypeError('Vendor Type is required');
    } else {
      setVendorTypeError("");
    }
  };
  
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vendorList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getVendorList = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/vendorassessment/vendordetails/",
      {
        credentials: "include",
      }
    )
    .then((res) => {
      if (!res.ok) {
        alert(handleError({ response: res }));
      }
      return res.json();
    })
      .then((data) => {
        if (data) {
          setVendorLists(data);
          setLoadingdelete(false);
          setLoadingVendor(false);
          setLoading(false);
          setopen_input(null);
          setOpen123(false);
        } else {
          setVendorLists(null);
          setLoadingdelete(false);
          setLoading(false);

          setLoadingVendor(false);
          setopen_input(null);
        }
      })
      .catch((err) => {
        alert(handleError({ response: err }));
      });
  };
  useEffect(async () => {
    getVendorList();
  }, []);

  const handleEntailmentRequest = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(cookie.load("tenant_id"))

    let hasError = false;

    if (!vendorName) {
      setNameError("Vendor Name is required");
      hasError = true;
    }
    if (!vendorType) {
      setVendorTypeError("Vendor Type is required");
      hasError = true;
    }
    
    if (!contactName) {
      setContactError("Contact person is required");
      hasError = true;
    }
    if (!email) {
      setEmailError("Contact Email is required");
      hasError = true;
    }
    if(nameError || addressError || vendorTypeError  || websiteError || contactError || emailError ){
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      setSnackbarMsg("Please correct the errors before submitting.");
      setSnackbarOpen(true);
      return;
  };

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",

      body: JSON.stringify({
        // vendor_no: event.target.vendor_no.value ? event.target.vendor_no.value : null,
        vendor_name: event.target.vendor_name.value
          ? event.target.vendor_name.value
          : null,
          address: event.target.address.value
          ? event.target.address.value
          : null,
          website: event.target.website.value
          ? event.target.website.value
          : null,
          contact_person: event.target.contact_person.value
          ? event.target.contact_person.value
          : null,
          contact_email: event.target.contact_email.value
          ? event.target.contact_email.value
          : null,
          vendor_type: event.target.vendor_type.value
          ? event.target.vendor_type.value
          : null,
          contract_start_date: event.target.contract_start_date.value
          ? event.target.contract_start_date.value
          : null,
          contract_end_date: event.target.contract_end_date.value
          ? event.target.contract_end_date.value
          : null,
        tenant: cookie.load("tenant_id"),
        created_by: decryption(cookie.load("user")),
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/vendorassessment/vendordetails/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201) {
          res.json().then((json) => {
            setOpen12(false);
            getVendorList();
          });
        } else {
          res.json().then((json) => {
            alert(handleError({ response: res }));
            setSnackbarMsg("Saved successfully!");
            setSnackbarOpen(true);
            getVendorList();
          });
        }
      })
      .catch((error) => {
        alert(handleError({ response: error }));
      });
  };
  const postEditVendor = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);

    if (updateNameError || updateAddressError || updateWebsiteError || updateContactError || updateEmailError|| updateDateError) {
      setLoading(false);
      setSnackbarMsg("Please correct the errors before Updating.");
      setSnackbarOpen(true);
      return;
    }

    const formData = {
      vendor_no: e.target.vendor_no.value,
      vendor_name: e.target.vendor_name.value,
      address: e.target.address.value,
      website: e.target.website.value,
      contact_person: e.target.contact_person.value,
      contact_email: e.target.contact_email.value,
      vendor_type: e.target.vendor_type.value,
      contract_start_date: e.target.contract_start_date.value,
      contract_end_date: e.target.contract_end_date.value,
      modified_by: decryption(cookie.load("user")),
    };

    const requestOption = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify(formData), // Send form data as JSON in the request body
    };

    fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/vendorassessment/vendordetails/" +
        postVendor_id +
        "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201 || res.status === 200) {
          res.json().then((json) => {
            setLoading(false);
            setopenid(false);
            setSnackbarMsg('Updated successfully!');
            setSnackbarOpen(true);
            getVendorList();
            setOpen123(false);
          });
        } else {
          res.json().then((json) => {
            alert(handleError({ response: res }));
            setopensnackbar({ open: true, message: json.vendor_no });
          });
        }
      })
      .catch((error) => {
        alert(handleError({ response: error }));
        setopensnackbar({ open: true, message: error });
      }
    );
  };

  const handleFile64 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function () {
      var base64data = reader.result;
      setExcelFile64(base64data);
    };
  };
  const handleFile = (e) => {
    setExcelFileError(e.target.value);
    var reader = new FileReader();
    let selectedFile = e.target.files[0];
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = (e) => {
      setExcelFile(e.target.result);
    };
  };

  const Csvsubmit = (event) => {
    setLoadingcsv(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        file_upload: excelFile64,
        created_by: decryption(cookie.load("user")),
      }),
    };

    const response = fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/vendorassessment/csvupload/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201) {
          res.json().then((json) => {
            setLoadingcsv(false);
            setopenid(false);
          });
          setOpen12(false);
          getVendorList();
        } else {          
          res.json().then((json) => {
            alert(handleError({ response: res }));
            setLoadingcsv(false);
          });
        }
      })
      .catch((error) => {
        alert(handleError({ response: error }));
      }
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <a style={{ }}>{children}</a>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClick12 = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchTerm(event.target.value);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vendorList.length) : 0;
  // const filteredVendors = applySortFilter(
  //   vendorList,
  //   getComparator(order, orderBy),
  //   filterName
  // );
  const filteredVendors = useMemo(() => {
    return stableSort(
      vendorList.filter((list) =>
        list.vendor_name
          ? list.vendor_name.toLowerCase().includes(searchTerm.toLowerCase())
          : false
      ),
      getComparator(order, orderBy)
    );
  }, [vendorList, searchTerm, order, orderBy]);
  const isNotFound = !filteredVendors.length && !!searchTerm;
  // on change states
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [excelFile64, setExcelFile64] = useState(null);

  // submit
  const [excelData, setExcelData] = useState(null);
  const [open_input, setopen_input] = useState(null);

  // it will contain array of objects

  // handle File
  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".csv",
  ];

  // submit function
  const handleSubmit1 = (e) => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      console.log(data);
      setExcelData(data);
    } else {
      setExcelData(null);
    }
  };
  const Deleteexel = () => {
    setExcelFileError(null);
  };
  const handleOpenMenu = (event, vendor_dbid, name, Email) => {
    setOPenname(name);
    setOpenemail(Email);
    setPostVendor_id(vendor_dbid);
    setOpen(event.currentTarget);
    setdeleteedit_id(vendor_dbid);
  };
  const [Loadingdelete, setLoadingdelete] = useState(false);

  const handleDelete = (event) => {
    handleClosemodel();
    setLoadingdelete(true);
    const requestOption = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        modified_by: decryption(cookie.load("user")),
      }),
    };

    const response = fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/vendorassessment/vendordetails/" +
        deleteedit_id +
        "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          res.json().then((json) => {
            setOpen(null);
            getVendorList();
            setopenid(false);
            setLoadingdelete(false);
            setOpen123(false);
          });
        } else if (res.status === 406) {
          setLoadingdelete(false);
        } else {
          setLoadingdelete(false);

          res.json().then((json) => {
            setLoadingdelete(false);
          });
        }
      },
      (error) => {}
    );
  };
  const handleEdit = (event) => {
    setOpen(null);
    setopen_input(deleteedit_id);
  };
  const [currentVendor, setCurrentVendor] = useState(true);

  const handleSwitchChange = (event) => {
    setCurrentVendor(event.target.checked);
  };
  const [opacity, setOpacity] = useState(1);

  const [vendorName, setVendorName] = useState("");
  const [nameError, setNameError] = useState(""); 
  const nameMaxLength = 30;

  const handleNameChange = (event) => {
    const value = event.target.value;
    setVendorName(value);
  
    if (!value) {
      setNameError("Vendor name is required");
    } else if (value.length > nameMaxLength) {
      setNameError(`Vendor name cannot exceed ${nameMaxLength} characters`);
    } else {
      setNameError(""); 
    }
  };

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const addressMaxLength = 100;

  const handleAddressChange = (event) => {
    const value = event.target.value;
    setAddress(value);

    if (value.length > addressMaxLength) {
      setAddressError(`Address cannot exceed ${addressMaxLength} characters.`);
    } else {
      setAddressError("");
    }
  };

  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const websiteMaxLength = 150;
  const webreg = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,})(:\d{1,5})?(\/[a-zA-Z0-9@:%._\+~#?&//=]*)?$/;
  const handleWebsiteChange = (event) => {
    const value = event.target.value;
    setWebsite(value);

    if (value.length > websiteMaxLength) {
      setWebsiteError(`Vendor Website cannot exceed ${websiteMaxLength} characters.`);
    } else if (!webreg.test(value)) {
      setWebsiteError("Invalid url format.");
    } else {
      setWebsiteError("");
    }
  };

  const [contactName, setContactName] = useState("");
  const [contactError, setContactError] = useState("");
  const contactMaxLength = 30;

  const handleContactChange = (event) => {
    const value = event.target.value;
    setContactName(value);
    if(! value){
       setContactError("Contact person is required");
    } else if (value.length > contactMaxLength) {
      setContactError("Contact person cannot exceed 50 characters");
    } else {
      setContactError("");
    }
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const emailMaxLength = 150;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    if(!value){
       setEmailError('Email is required')
    } else if (value.length > emailMaxLength) {
       setEmailError(`Email cannot exceed ${emailMaxLength} characters.`);
    } else if (!emailRegex.test(value)) {
       setEmailError("Invalid email format.");
    } else {
       setEmailError("");
    }
  };

  


   const [updatevendorName, setUpdateVendorName] = useState("");
   const [updateNameError, setUpdateNameError] = useState(""); 
   
    const handleUpdateNameChange = (event) => {
    const value = event.target.value;
    setUpdateVendorName(value);
   
    if (!value) {
      setUpdateNameError("Vendor name is required");
    } else if (value.length > nameMaxLength) {
      setUpdateNameError(`Vendor name cannot exceed ${nameMaxLength} characters`);
    } else {
      setUpdateNameError(""); 
    }
   };
 
   const [updateAddress, setUpdateAddress] = useState("");
   const [updateAddressError, setUpdateAddressError] = useState("");
  
   const handleUpdateAddressChange = (event) => {
     const value = event.target.value;
     setUpdateAddress(value);
 
     if (value.length > addressMaxLength ) {
       setUpdateAddressError(`Address cannot exceed ${addressMaxLength} characters.`);
     } else {
       setUpdateAddressError("");
     }

    };
    const [updatewebsite, setUpdateWebsite] = useState("");
    const [updateWebsiteError, setUpdateWebsiteError] = useState("");
  
    const handleUpdateWebsiteChange = (event) => {
     const value = event.target.value;
     setUpdateWebsite(value);
 
     if (value.length >websiteMaxLength) {
       setUpdateWebsiteError(`Vendor Website cannot exceed ${websiteMaxLength} characters.`);
     } else if (!webreg.test(value)) {
       setUpdateWebsiteError("Invalid url format.");
     } else {
       setUpdateWebsiteError("");
     }
   };
 
   const [updateContactName, setUpdateContactName] = useState("");
   const [updateContactError, setUpdateContactError] = useState("");
 
   const handleUpdateContactChange = (event) => {
     const value = event.target.value;
     setUpdateContactName(value);
     if(! value){
        setUpdateContactError("Contact person is required");
     } else if (value.length > contactMaxLength) {
       setUpdateContactError(`Contact person cannot exceed ${contactMaxLength} characters`);
     } else {
       setUpdateContactError("");
     }
   };
 
   const [updateEmail, setUpdateEmail] = useState("");
   const [updateEmailError, setupdateEmailError] = useState("");
 
   const handleUpdateEmailChange = (event) => {
     const value = event.target.value;
     setUpdateEmail(value);
     if(!value){
        setupdateEmailError('Email is required')
     } else if (value.length >  emailMaxLength) {
        setupdateEmailError(`Email cannot exceed ${ emailMaxLength} characters.`);
     } else if (!emailRegex.test(value)) {
        setupdateEmailError("Invalid email format.");
     } else {
        setupdateEmailError("");
     }
   }; 

   const [updateStartDate, setUpdateStartDate] = useState("");
   const [updateEndDate, setUpdateEndDate] = useState("");
   const [updateDateError, setUpdateDateError] = useState("");
   
   const updateValidateDates = (start, end) => {
 
     if (!start && !end) {
       setUpdateDateError("Start date and End date are required.");
     } else if (!start) {
       setUpdateDateError("Start date is required.");
     } else if (!end) {
      setUpdateDateError("End date is required.");
     } else if (start > end) {
       setUpdateDateError("End date cannot be earlier than the start date.");
     } else {
       setUpdateDateError("");
     }
   };
   
   const handleUpdateStartDateChange = (event) => {
     const value = event.target.value;
     setUpdateStartDate(value);
     updateValidateDates(value, updateEndDate);
   };
   
   const handleUpdateEndDateChange = (event) => {
     const value = event.target.value;
     setUpdateEndDate(value);
     updateValidateDates(updateStartDate, value);
   };
   
  return (
    <div className="vendor-">
      {loadingVendor ? (
        <Box style={{ padding: 30, width: "96%" }}>
          <Skeleton style={{ height: "70px", width: "20%" }} animation="wave" />
          <Skeleton style={{ height: "100px" }} />
          <Skeleton style={{ height: "100px" }} animation="wave" />
          <Skeleton style={{ height: "100px" }} animation={false} />
          <Skeleton style={{ height: "100px" }} />
          <Skeleton style={{ height: "100px" }} animation="wave" />
          <Skeleton style={{ height: "100px" }} animation="wave" />
        </Box>
      ) : (
        <>
          <div className="ADD_USER_DRAWER">
            <Drawer
              sx={{
                width: "40%",
                maxWidth: "40%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: "40%",
                  maxWidth: "40%",
                  overflow: "hidden",
                },
              }}
              anchor={"right"}
              open={open12}
              onClose={toggleDrawer1(false)}
            >
              <Box sx={{ overflowY: "auto" }}>
                {" "}
                <div className="">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      onMouseDown={() => setOpacity(0.5)}
                      onMouseUp={() => setOpacity(1)}
                      onMouseLeave={() => setOpacity(1)}
                      onTouchStart={() => setOpacity(0.5)}
                      onTouchEnd={() => setOpacity(1)}
                    >
                      <DisabledByDefaultIcon
                        onClick={() => {
                          setOpen12(false);
                        }}
                        style={{
                          overflow: "visible",
                          color: "#eeeeee",
                          fontSize: "30px",
                          marginLeft: "-25px",
                          marginTop: "-4px",
                          cursor: "pointer",
                          opacity: opacity,
                        }}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "30px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {{ ar: "إضافة بائع", sp: "Spanish" }[
                        cookie.load("Language")
                      ] || "Add Vendor"}
                    </p>
                  </div>
                  <br />
                  <div style={{}}>
                    <Box sx={{}}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={
                              { ar: "تحميل فردي", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Single Upload"
                            }
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={
                              { ar: "تحميل جماعي", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Bulk Upload"
                            }
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <form
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                          onSubmit={handleEntailmentRequest}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              float: "right",
                            }}
                          >
                            <Button className="submit-button"
                              loading={loading}
                              type="submit"
                              startIcon={
                                <Iconify
                                  icon={"mdi:account-tick-outline"}
                                  sx={{}}
                                />
                              }
                              // color="success"
                              // sx={{
                              //   float: "right",
                              //   marginRight: "15px",
                              //   color: "white",
                              //   background: "#001b2b",
                              // }}
                              // variant="contained"
                            >
                              {{ ar: "حفظ البائع", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Save"}
                            </Button>
                            <Button className="cancel-button"
                              onClick={() => {
                                setOpen12(false);
                              }}
                              // color="inherit"
                              // sx={{ float: "right", marginRight: "15px" }}
                              // variant="outlined"
                            >
                              {{ ar: "إغلاق", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Close"}
                            </Button>
                          </div>

                          {/* <TextField
                            required
                            name="vendor_no"
                            id="vendor_no"
                            label={
                              { ar: "معرّف البائع", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Vendor Id"
                            }
                            variant="outlined"
                          /> */}

                          <TextField
                            name="vendor_name"
                            id="vendor_name"
                            label={
                              { ar: "اسم البائع", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Vendor Name*"
                            }
                            variant="outlined"
                            value={vendorName}
                            autoFocus={focusField === "vendor_name"}
                            onFocus={() => setFocusField("vendor_name")}
                            onBlur={() => setFocusField(null)}
                            onChange={handleNameChange}
                            error={nameError}
                            helperText={nameError}
                          />
                          <FormControl variant="outlined" fullWidth error={!!vendorTypeError}>
                          <InputLabel id="vendor-type-label">
                            { { ar: "نوع البائع", sp: "Spanish" }[cookie.load("Language")] || "Vendor Type*" }
                          </InputLabel>
                          <Select
                            labelId="vendor-type-label"
                            id="vendor-type-select"
                            name="vendor_type"
                            label={
                              { ar: "نوع البائع", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Vendor Type*"
                            }
                            value={vendorType}
                            onChange={handleVendorTypeChange}
                          >
                            <MenuItem value="Cloud">Cloud</MenuItem>
                            <MenuItem value="Human Resource Management Systems (HRMS)">Human Resource Management Systems (HRMS)</MenuItem>
                            <MenuItem value="Software Development">Software Development</MenuItem>
                            <MenuItem value="Physical Security">Physical Security</MenuItem>
                            <MenuItem value="Information Security">Information Security</MenuItem>
                            <MenuItem value="Customer Relationship Management (CRM)">Customer Relationship Management (CRM)</MenuItem>
                            <MenuItem value="Business Intelligence and Analytics">Business Intelligence and Analytics</MenuItem>
                            <MenuItem value="Collaboration and Communication">Collaboration and Communication</MenuItem>
                            <MenuItem value="Enterprise Resource Planning (ERP)">Enterprise Resource Planning (ERP)</MenuItem>
                            <MenuItem value="Data Management and Storage">Data Management and Storage</MenuItem>
                            <MenuItem value="Finance and Accounting">Finance and Accounting</MenuItem>
                            <MenuItem value="Outsourcing">Outsourcing</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                          {vendorTypeError && <FormHelperText>{vendorTypeError}</FormHelperText>}
                          </FormControl>
                          <TextField
                            name="address"
                            id="address"
                            label={
                              { ar: "عنوان البائع", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Vendor Address"
                            }
                            variant="outlined"
                            value={address}
                            autoFocus={focusField === "Vendor Address"}
                            onFocus={() => setFocusField("Vendor Address")}
                            onBlur={() => setFocusField(null)}
                            onChange={handleAddressChange}
                            error={addressError}
                            helperText={addressError}
                          />
                          <TextField
                            name="website"
                            id="website"
                            label={
                              { ar: "موقع البائع", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Vendor Website"
                            }
                            variant="outlined"
                            value={website}
                            autoFocus={focusField === "Vendor Website"}
                            onFocus={() => setFocusField("Vendor Website")}
                            onBlur={() => setFocusField(null)}
                            onChange={handleWebsiteChange}
                            error={Boolean(websiteError)}
                            helperText={websiteError}
                          />
                          <TextField
                            name="contact_person"
                            id="contact_person"
                            label={
                              { ar: "شخص الاتصال", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Contact Person*"
                            }
                            variant="outlined"
                            value={contactName}
                            autoFocus={focusField === "contact_person"}
                            onFocus={() => setFocusField("contact_person")}
                            onBlur={() => setFocusField(null)}
                            onChange={handleContactChange}
                            error={contactError}
                            helperText={contactError}
                          />
                          <TextField
                            type="email"
                            name="contact_email"
                            id="contact_email"
                            label={
                              { ar: "بريد الاتصال", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Contact Email*"
                            }
                            variant="outlined"
                            value={email}
                            autoFocus={focusField === "contact_email"}
                            onFocus={() => setFocusField("contact_email")}
                            onBlur={() => setFocusField(null)}
                            onChange={handleEmailChange}
                            error={Boolean(emailError)}
                            helperText={emailError}
                          />
                          <TextField
                            
                            type="date"
                            name="contract_start_date"
                            id="contract_start_date"
                            label={
                              { ar: "تاريخ بدء العقد", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Contract Start Date*"
                            }
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          
                          />
                          <TextField
                         
                            type="date"
                            name="contract_end_date"
                            id="contract_end_date"
                            label={
                              { ar: "تاريخ انتهاء العقد", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Contract End Date*"
                            }
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            
                          />
                        </form>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div>
                          <div className="form">
                            <form
                              className="form-group"
                              autoComplete="off"
                              onSubmit={handleSubmit1}
                              style={{
                                flexDirection: "column",
                                color: "#001b2b",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  float: "right",
                                  gap: "20px",
                                }}
                              >
                                <LoadingButton
                                  onClick={() => {
                                    setOpen12(false);
                                  }}
                                  style={{
                                    color: "#808080",
                                    height: "35px",
                                    width: "80px",
                                    background: "#eeeeee",
                                    borderRadius: "5px",
                                    marginTop: "10px",
                                  }}
                                  variant="outlined"
                                >
                                  {{ ar: "إغلاق", sp: "Spanish" }[
                                    cookie.load("Language")
                                  ] || "CLOSE"}
                                </LoadingButton>
                                <LoadingButton
                                  disabled={excelFileError ? false : true}
                                  onClick={() => {
                                    handleOpen1();
                                    handleSubmit1();
                                  }}
                                  style={{
                                    height: "35px",
                                    borderRadius: "5px",
                                    marginTop: "10px",
                                    background: excelFileError
                                      ? "rgb(0, 34, 54)"
                                      : "#d8d8d8",
                                    color: excelFileError
                                      ? "#ffffff"
                                      : "#808080",
                                  }}
                                  type="submit"
                                  variant="contained"
                                >
                                  {{ ar: "معاينة وتقديم", sp: "Spanish" }[
                                    cookie.load("Language")
                                  ] || "PREVIEW & SUBMIT"}
                                </LoadingButton>
                              </div>
                              <br />

                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  gap: 5,
                                }}
                              >
                                <p style={{ fontWeight: "bold" }}>
                                  {{ ar: "تنسيق ملف العينة", sp: "Spanish" }[
                                    cookie.load("Language")
                                  ] || "Sample File format"}
                                </p>
                                <div>
                                  {" "}
                                  <a href={sampleFile} download="sample.xlsx">
                                    <SimCardDownloadIcon
                                      style={{
                                        marginTop: "9px",
                                        color: "#4285f4",
                                        cursor: "pointer",
                                      }}
                                      download="sample.xlsx"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  gap: 5,
                                }}
                              >
                                <p style={{ fontWeight: "bold" }}>
                                  {{
                                    ar: "تنسيق الملف المقبول:",
                                    sp: "Spanish",
                                  }[cookie.load("Language")] ||
                                    "Accepted File Format:"}
                                </p>
                                <p> csv,xlsx,xls.</p>
                              </div>

                              <br></br>
                              {!excelFileError ? (
                                <input
                                  style={{
                                    marginBottom: "25px",
                                    color: "#001b2b",
                                  }}
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => {
                                    handleFile(e);
                                    handleFile64(e);
                                  }}
                                  required
                                ></input>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      marginTop: 5 + "px",
                                      fontWeight: "bold",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    1.
                                    {excelFileError.replace(/^.*[\\\/]/, "")}
                                    <CancelIcon
                                      onClick={Deleteexel}
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      fontSize="small"
                                    />
                                  </div>
                                  <br />
                                </div>
                              )}
                            </form>
                          </div>
                          <Modal
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "10%",
                                  overflow: "hidden",
                                }}
                              >
                                <div style={{ width: "60%" }}>
                                  <h5>View Excel file</h5>
                                  <a
                                    id="modal-modal-description"
                                    style={{
                                      mt: 2,
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#c7ad00",
                                        fontSize: "bold",
                                      }}
                                    >
                                      Note:
                                    </p>
                                    Check that the coloums are correct.
                                  </a>
                                </div>
                                <div
                                  style={{
                                    float: "right",
                                    width: "40%",
                                    gap: "20px",
                                  }}
                                >
                                  <LoadingButton
                                    loading={loadingcsv}
                                    onClick={Csvsubmit}
                                    variant="contained"
                                    startIcon={
                                      <SaveIcon icon="eva:plus-fill" />
                                    }
                                    style={{
                                      float: "right",
                                      background: "rgb(0, 34, 54)",
                                    }}
                                  >
                                    Submit
                                  </LoadingButton>
                                  <LoadingButton
                                    onClick={() => setOpen1(false)}
                                    variant="contained"
                                    style={{
                                      float: "right",
                                      background: "#808080",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Cancel
                                  </LoadingButton>
                                </div>
                              </div>

                              <br />
                              <div
                                style={{
                                  height: "90%",
                                  overflow: "scroll",
                                }}
                                className="viewer"
                              >
                                {excelData === null && <>No file selected</>}
                                {excelData !== null && (
                                  <div className="table-responsive">
                                    <table
                                      style={{
                                        height: "100%",
                                        overflow: "hidden",
                                        textAlign: "center",
                                      }}
                                      className="table"
                                    >
                                      <thead
                                        style={{
                                          height: "50px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <tr>
                                          {/* <th scope="col">
                                            {" "}
                                            <a
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Vendor ID
                                            </a>
                                          </th> */}
                                          <th scope="col">Vendor Name</th>
                                          <th scope="col">Vendor Type</th>
                                          <th scope="col">Vendor Address</th>
                                          <th scope="col">Vendor Website</th>
                                          <th scope="col">Contact Person</th>
                                          <th scope="col">Contact Email</th>
                                          <th scope="col">Contract Start Date</th>
                                          <th scope="col">Contract End Date</th>
                                        </tr>
                                      </thead>
                                      <br />
                                      <tbody
                                        style={{
                                          height: 200,
                                          overflow: "scroll",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <Data excelData={excelData} />
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </TabPanel>
                    </Box>
                  </div>
                </div>
              </Box>{" "}
            </Drawer>
          </div>

          <div className="EDIT_DRAWER">
            <Drawer
              sx={{
                width: "40%",
                maxWidth: "40%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: "40%",
                  maxWidth: "40%",
                  overflow: "hidden",
                },
              }}
              anchor={"right"}
              open={open123}
              onClose={toggleDrawer12(false)}
            >
              <Box sx={{ overflowY: "auto" }}>
                {" "}
                <Modal
                  open={openmodel}
                  onClose={handleClosemodel}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...style, width: 400, height: 300 }}>
                    <h2>
                      {{
                        ar: "هل أنت متأكد أنك تريد حذف هذا البائع؟",
                        sp: "Spanish",
                      }[cookie.load("Language")] ||
                        "Are you sure you want to delete this vendor?"}
                    </h2>
                    <p>
                      {{
                        ar: "هذه العملية غير قابلة للتراجع وستزيل البائع بشكل دائم من النظام.",
                        sp: "Spanish",
                      }[cookie.load("Language")] ||
                        "This action is irreversible and will permanently remove the vendor from the system."}
                      <br /> <br />
                      {{ ar: "تفاصيل", sp: "Spanish" }[
                        cookie.load("Language")
                      ] || "Details :"}
                      <br />
                      {{ ar: "اسم", sp: "Spanish" }[cookie.load("Language")] ||
                        "Name :"}{" "}
                      {openname}
                      <br />
                      {{ ar: "بريد إلكتروني", sp: "Spanish" }[
                        cookie.load("Language")
                      ] || "Email :"}{" "}
                      {Openemail}
                    </p>
                    <br />
                    <Button
                      disabled={Loadingdelete}
                      style={{
                        float: "right",
                      }}
                      variant="outlined"
                      color="error"
                      onClick={(event) => handleDelete(event)}
                    >
                      {{ ar: "حذف البائع", sp: "Spanish" }[
                        cookie.load("Language")
                      ] || "Delete Vendor"}
                    </Button>{" "}
                    <Button
                      color="secondary"
                      style={{
                        float: "right",
                        color: "#808080",
                        right: "20px",
                      }}
                      onClick={handleClosemodel}
                    >
                      {{ ar: "إلغاء", sp: "Spanish" }[cookie.load("Language")] ||
                        "CANCEL"}
                    </Button>
                  </Box>
                </Modal>{" "}
                <form style={{}} onSubmit={postEditVendor} className="">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "30px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {{ ar: "تفاصيل البائع", sp: "Spanish" }[
                        cookie.load("Language")
                      ] || "Vendor Details"}
                    </p>
                  </div>
                  <br />
                  <Collapse sx={{ padding: "10px" }} in={opensnackbar.open}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setopensnackbar({ open: false, message: "" });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {opensnackbar.message}
                    </Alert>
                  </Collapse>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      float: "right",
                    }}
                  >
                    {/* <Button
                      onClick={handleOpenmodel}
                      startIcon={
                        <Iconify icon={"eva:trash-2-outline"} sx={{}} />
                      }
                      color="error"
                      sx={{ float: "right", marginRight: "15px" }}
                      variant="outlined"
                    >
                      {Loadingdelete
                        ? "Deleteing"
                        : { ar: "يمسح", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Delete"}
                    </Button> */}
                    <Button className="update-button"
                      type="submit"
                      startIcon={
                        <Iconify icon={"mdi:account-tick-outline"} sx={{}} />
                      }
                      // color="success"
                      // sx={{
                      //   float: "right",
                      //   marginRight: "15px",
                      //   color: "white",
                      //   background: "#001b2b",
                      // }}
                      // variant="contained"
                    >
                      {{ ar: "تحديث", sp: "Spanish" }[
                        cookie.load("Language")
                      ] || "Update"}
                    </Button>
                  </div>
                  <br />
                  <div
                    style={{
                      padding: "30px",
                    }}
                  >
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <TextField
                        fullWidth
                        required
                        defaultValue={viewdetails_data.vendor_no}
                        name="vendor_no"
                        id="vendor_no"
                        label={
                          { ar: "معرّف البائع", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Vendor Id"
                        }
                        variant="outlined"
                        disabled={true}
                      />

                      <TextField
                        fullWidth
                        defaultValue={viewdetails_data.vendor_name}
                        name="vendorName"
                        id="vendor_name"
                        label={
                          { ar: "اسم البائع", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Vendor Name"
                        }
                        variant="outlined"
                        onChange={handleUpdateNameChange}
                        error={!!updateNameError}
                        helperText={updateNameError}
                        autoFocus={focusField === "Vendor Name"}
                        onFocus={() => setFocusField( "Vendor Name")}
                        onBlur={() => setFocusField(null)}
                      />
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="vendor-type-label">
                          { { ar: "نوع البائع", sp: "Spanish" }[cookie.load("Language")] || "Vendor Type" }
                        </InputLabel>
                        <Select
                          labelId="vendor-type-label"
                          id="vendor-type-select"
                          name="vendor_type"
                          label={
                            { ar: "نوع البائع", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "Vendor Type"
                          }
                          defaultValue={viewdetails_data.vendor_type}
                        >
                          <MenuItem value="Cloud">Cloud</MenuItem>
                          <MenuItem value="Human Resource Management Systems (HRMS)">Human Resource Management Systems (HRMS)</MenuItem>
                          <MenuItem value="Software Development">Software Development</MenuItem>
                          <MenuItem value="Physical Security">Physical Security</MenuItem>
                          <MenuItem value="Information Security">Information Security</MenuItem>
                          <MenuItem value="Customer Relationship Management (CRM)">Customer Relationship Management (CRM)</MenuItem>
                          <MenuItem value="Business Intelligence and Analytics">Business Intelligence and Analytics</MenuItem>
                          <MenuItem value="Collaboration and Communication">Collaboration and Communication</MenuItem>
                          <MenuItem value="Enterprise Resource Planning (ERP)">Enterprise Resource Planning (ERP)</MenuItem>
                          <MenuItem value="Data Management and Storage">Data Management and Storage</MenuItem>
                          <MenuItem value="Finance and Accounting">Finance and Accounting</MenuItem>
                          <MenuItem value="Outsourcing">Outsourcing</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        defaultValue={viewdetails_data.address}
                        name="address"
                        id="address"
                        label={
                          { ar: "عنوان البائع", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Vendor Address"
                        }
                        variant="outlined"
                        onChange={handleUpdateAddressChange}
                        error={updateAddressError}
                        helperText={updateAddressError}
                        autoFocus={focusField === "Vendor Address"}
                        onFocus={() => setFocusField("Vendor Address")}
                        onBlur={() => setFocusField(null)}
                      />
                      <TextField
                        defaultValue={viewdetails_data.website}
                        name="website"
                        id="website"
                        label={
                          { ar: "موقع البائع", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Vendor Website"
                        }
                        variant="outlined"
                        onChange={handleUpdateWebsiteChange}
                        error={Boolean(updateWebsiteError)}
                        helperText={updateWebsiteError}
                        autoFocus={focusField === "Vendor Website"}
                        onFocus={() => setFocusField("Vendor Website")}
                        onBlur={() => setFocusField(null)}
                      />
                      <TextField
                        defaultValue={viewdetails_data.contact_person}
                        name="contact_person"
                        id="contact_person"
                        label={
                          { ar: "شخص الاتصال", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Contact Person"
                        }
                        variant="outlined"
                        onChange={handleUpdateContactChange}
                        error={updateContactError}
                        helperText={updateContactError}
                        autoFocus={focusField === "Contact Person"}
                        onFocus={() => setFocusField("Contact Person")}
                        onBlur={() => setFocusField(null)}
                      />
                      <TextField
                        defaultValue={viewdetails_data.contact_email}
                        name="contact_email"
                        id="contact_email"
                        label={
                          { ar: "بريد الاتصال", sp: "Spanish" }[
                            cookie.load("Language")
                          ] || "Contact Email"
                        }
                        variant="outlined"
                        onChange={handleUpdateEmailChange}
                        error={Boolean(updateEmailError)}
                        helperText={updateEmailError}
                        autoFocus={focusField === "Contact Email"}
                        onFocus={() => setFocusField("Contact Email")}
                        onBlur={() => setFocusField(null)}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          style={{ minWidth: "50%", maxWidth: "100%" }}
                          defaultValue={viewdetails_data.contract_start_date}
                          type="date"
                          name="contract_start_date"
                          id="contract_start_date"
                          label={
                            { ar: "تاريخ بدء العقد", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "Contract Start Date"
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleUpdateStartDateChange}
                          error={Boolean(updateDateError)}
                        />
                        <TextField
                          style={{ minWidth: "50%" }}
                          
                          defaultValue={viewdetails_data.contract_end_date}
                          type="date"
                          name="contract_end_date"
                          id="contract_end_date"
                          label={
                            { ar: "تاريخ انتهاء العقد", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "Contract End Date"
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleUpdateEndDateChange}
                          error={Boolean(updateDateError)}
                          helperText={updateDateError}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Box>{" "}
            </Drawer>
          </div>

          <div
            style={{
              width: "100%",
              minHeight: "100%",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <UserListToolbar
                  Search_bar_name={
                    { ar: "بحث عن البائع", sp: "Spanish" }[
                      cookie.load("Language")
                    ] || "Search Vendor"
                  }
                  numSelected={selected.length}
                  filterName={searchTerm}
                  onFilterName={handleFilterByName}
                />
                <Box
                  sx={{
                    marginTop: 3,
                    marginRight: 2,
                  }}
                >
                  <Button  className="create-add-buttons"
                   // variant="contained"
                  //  sx={{ color: "#fff", background: "#001b2b" }}
                    onClick={toggleDrawer1(true)}
                  // color="primary"
                    aria-label="add"
                  >
                    {" "}
                    {{ ar: "إضافة بائع", sp: "Spanish" }[
                      cookie.load("Language")
                    ] || "Add Vendor"}
                  </Button>
                </Box>
              </div>

              <div style={{ padding: "15px" }}>
                <div>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={vendorList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredVendors
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              vendor_no,
                              vendor_name,
                              vendor_type,
                              vendor_score,
                              criticality,
                              address,
                              website,
                              contact_person,
                              contact_email,
                              contract_start_date,
                              contract_end_date,
                              vendor_dbid,
                            } = row;
                            const selectedVendor = selected.indexOf(vendor_no) !== -1;

                            return (
                              <TableRow
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setOpen123(true);
                                  setViewdetails_data(row);
                                  handleOpenMenu(
                                    e,
                                    vendor_dbid,
                                    vendor_name,
                                    vendor_type
                                  );
                                }}
                                hover
                                key={vendor_no}
                                tabIndex={-1}
                                role="checkbox"
                                selected={selectedVendor}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                >
                                  <Stack
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    textAlign="center"
                                    spacing={2}
                                  >
                                    <a
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                      variant="subtitle2"
                                      noWrap
                                    >
                                      {vendor_dbid === open_input ? (
                                        <div>
                                          <TextField
                                            id="vendor_no"
                                            label={
                                              {
                                                ar: "معرّف البائع",
                                                sp: "Spanish",
                                              }[cookie.load("Language")] ||
                                              "Vendor Id"
                                            }
                                            variant="outlined"
                                            name="vendor_no"
                                            defaultValue={vendor_no}
                                          ></TextField>
                                        </div>
                                      ) : (
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                            color: "#808080",
                                          }}
                                        >
                                          {vendor_no}
                                        </a>
                                      )}
                                    </a>
                                  </Stack>
                                </TableCell>

                                <TableCell align="left">
                                  {vendor_dbid === open_input ? (
                                    <TextField
                                      id="vendor_name"
                                      label={
                                        { ar: "اسم البائع", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Vendor Name"
                                      }
                                      variant="outlined"
                                      name="vendor_name"
                                      defaultValue={vendor_name}
                                    ></TextField>
                                  ) : (
                                    <a
                                      style={{
                                        textTransform: "capitalize",
                                        color: "#808080",
                                      }}
                                    >
                                      {vendor_name}
                                    </a>
                                  )}
                                </TableCell>

                                <TableCell align="left">
                                  {vendor_dbid === open_input ? (
                                    <TextField
                                      id="vendor_type"
                                      label={
                                        { ar: "نوع البائع", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Vendor Type"
                                      }
                                      variant="outlined"
                                      name="vendor_type"
                                      defaultValue={vendor_type}
                                    ></TextField>
                                  ) : (
                                    <a
                                      style={{
                                        textTransform: "capitalize",
                                        fontWeight: "lighter",
                                        color: "#808080",
                                      }}
                                    >
                                      {vendor_type}
                                    </a>
                                  )}
                                </TableCell>

                                <TableCell align="left">
                                  {vendor_dbid === open_input ? (
                                    <TextField
                                      id="vendor_score"
                                      label={
                                        { ar: "درجة البائع", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Vendor Score"
                                      }
                                      variant="outlined"
                                      name="staffmail"
                                      defaultValue={vendor_score}
                                    ></TextField>
                                  ) : (
                                    <a
                                      style={{
                                        textTransform: "lowercase",
                                        color: "#808080",
                                      }}
                                    >
                                      {vendor_score}
                                    </a>
                                  )}
                                </TableCell>

                                <TableCell align="left">
                                  {vendor_dbid === open_input ? (
                                    <TextField
                                      id="criticality"
                                      label={
                                        { ar: "أهمية", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Criticality"
                                      }
                                      variant="outlined"
                                      name="criticality"
                                      defaultValue={criticality}
                                    ></TextField>
                                  ) : (
                                    <a
                                      style={{
                                        textTransform: "lowercase",
                                        color: "#808080",
                                      }}
                                    >
                                      {criticality}
                                    </a>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <Paper
                                sx={{
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                <a
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "16px",
                                    color: "red",
                                  }}
                                >
                                  Not found !
                                </a>

                                <a
                                  style={{ textTransform: "capitalize" }}
                                  variant="body2"
                                >
                                  <br />
                                  <br />
                                  No Name found for &nbsp;
                                  <strong>&quot;{searchTerm}&quot;</strong>.
                                </a>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
              </div>

              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={vendorList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={1000}
              onClose={snackbarClose}
              message={<span id="message-id">{snackbarMsg}</span>}
              action={[
                <IconButton
                  key="close"
                  arial-label="Close"
                  color="inherit"
                  onClick={snackbarClose}
                >
                </IconButton>,
              ]}
              sx={{
                '& .MuiSnackbarContent-root': {
                  backgroundColor: 
                    (snackbarMsg === 'Updated successfully!' || snackbarMsg === 'Saved successfully!')
                    ? 'green' 
                    : '#ba1209',
                },
              }}
            />
        </>
      )}
    </div>
  );
}
export default Vendor;
